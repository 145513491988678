<template>
    <div>
        <b-form>
            <b-form-group role="form-group">
                <label for="input-name">Name*:</label>
                <b-form-input id="input-name" v-model="platformexportfilesendconfig.name" :state="nameState"
                    aria-describedby="input-name-help input-name-feedback" placeholder="Name" trim></b-form-input>
                <b-form-invalid-feedback id="input-name-feedback">
                    Erforderlich
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Plattform-FTP*:">
                <b-form-select v-model="platformexportfilesendconfig.ownoffersclient_id"
                    :options="ownoffersplatformftps" value-field="id" text-field="name" disabled-field="notEnabled"
                    required></b-form-select>
            </b-form-group>
            <b-form-group role="form-group">
                <label for="input-targetpath">Dateipfad:</label>
                <b-form-input id="input-targetpath" v-model="platformexportfilesendconfig.targetpath"
                    aria-describedby="input-targetpath-help input-targetpath-feedback" placeholder="Ziel-Dateipfad"
                    trim></b-form-input>
            </b-form-group>
            <b-form-group role="form-group">
                <label for="input-targetfilename">Dateiname:</label>
                <b-form-input id="input-targetfilename" v-model="platformexportfilesendconfig.targetfilename"
                    aria-describedby="input-targetfilename-help input-targetfilename-feedback" placeholder="Dateiname"
                    trim></b-form-input>
                <b-form-invalid-feedback id="input-targetfilename-feedback">
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-userandomstocks" label="Zufallsbestände anwenden:"
                label-for="checkbox-userandomstocks" description="" style="margin-top: 10px;">
                <b-form-checkbox v-model="platformexportfilesendconfig.userandomstocks" name="check-userandomstocks"
                    switch value="1" unchecked-value="0">
                    Die Zufallsbestände werden {{ platformexportfilesendconfig.userandomstocks ? "angewendet" : "nicht angewendet!" }}
                </b-form-checkbox>
            </b-form-group>
            <b-form-group id="input-group-maxmainstock" label="Maximaler Hauptbestand:" label-for="input-maxmainstock"
                description="Maximaler Bestand des Hauplieferantes Inter-Drive." style="margin-top: 10px;">
                <b-form-input id="input-maxmainstock" v-model="platformexportfilesendconfig.maxmainstock"
                    aria-describedby="input-maxmainstock-help input-maxmainstock-feedback"
                    placeholder="Maximaler Hauptbestand" :state="maxmainstockState" trim type="number" min=1 number
                    class="mb-2 mr-sm-2 mb-sm-0" required>
                </b-form-input>
                <b-form-invalid-feedback id="input-maxmainstock-feedback">
                    Erforderlich
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-delimiter" label="Trennzeichen der Exportdatei:" label-for="input-delimiter"
                description="Trennzeichen der Exportdatei. Standart Trenzeichen ist ein Semikolon"
                style="margin-top: 10px;">
                <b-form-input id="input-delimiter" v-model="platformexportfilesendconfig.delimiter"
                    aria-describedby="input-delimiter-help input-delimiter-feedback" placeholder="Trennzeichen"
                    :state="delimiterState" trim class="mb-2 mr-sm-2 mb-sm-0" required></b-form-input>
                <b-form-invalid-feedback id="input-delimiter-feedback">
                    Erforderlich
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-decimalformat" label="Währungstrennzeichen:" label-for="input-decimalformat"
                description="Trennzeichen der Währung. Komma oder Punkt." style="margin-top: 10px;">
                <b-form-input id="input-decimalformat" v-model="platformexportfilesendconfig.decimalformat"
                    aria-describedby="input-decimalformat-help input-decimalformat-feedback"
                    placeholder="Währungstrennzeichen" :state="decimalformatState" trim class="mb-2 mr-sm-2 mb-sm-0"
                    required></b-form-input>
                <b-form-invalid-feedback id="input-decimalformat-feedback">
                    Erforderlich
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-isexportfilecolumnsfrom"
                label="Spaltenkonfiguration von der anderen Konfiguration übernehmen:"
                label-for="checkbox-isexportfilecolumnsfrom" description="" style="margin-top: 10px;">
                <b-form-checkbox v-model="platformexportfilesendconfig.isexportfilecolumnsfrom"
                    name="check-isexportfilecolumnsfrom" switch value="1" unchecked-value="0">
                </b-form-checkbox>
            </b-form-group>
            <b-form-group role="form-group" v-if="platformexportfilesendconfig.isexportfilecolumnsfrom">
                <label for="input-name">Spaltenkonfiguration von der Konfiguration:</label>
                <b-form-select v-model="platformexportfilesendconfig.exportfilecolumnsfrom"
                    :options="exportfilecolumnsfromoptions" value-field="id" text-field="name"
                    disabled-field="notEnabled" required></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-desc" label="Beschreibung:" label-for="input-desc" description=""
                style="margin-top: 10px;">
                <b-form-input id="input-desc" v-model="platformexportfilesendconfig.description"
                    placeholder="Beschreibung" trim></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-lastaction" label="Status:" label-for="input-desc" description=""
                style="margin-top: 10px;">
                <b-form-select v-model="platformexportfilesendconfig.LastAction"
                    :options="lastActionOptions"></b-form-select>
            </b-form-group>
            <slot name="submit" :submit="submit">
                <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText"
                    :disabled="!validated" style="margin-top:10px;">
            </slot>
        </b-form>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/platformexportfilesendconfig/'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },

        headers: {
            default: null
        },
        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },

        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        lastActionOptions:[
            { value: 0, text: 'Deaktiviert' },
            { value: 1, text: 'Aktiviert' },
            { value: 2, text: 'Scheinangebote' },
        ],
        exportfilecolumnsfromoptions: [],
        ownoffersplatformftps: [],
        platformsendtypes: {},
        platformexportfilesendconfig: {
            platform_id: 0,
            ownoffersclient_id: null,
            name: null,
            targettype: 1,
            targetaddress: null,
            targetpath: null,
            targetfilename: null,
            targetusername: null,
            targetpassword: null,
            userandomstocks: 0,
            maxmainstock: null,
            delimiter:';',
            decimalformat:'DE',
            isexportfilecolumnsfrom: 0,
            exportfilecolumnsfrom: null,
            description: null,
            LastAction: 1,
        },
    }),

    created() {
        this.platformexportfilesendconfig = this.editdata;
        this.getPlatformSendTypes();
        this.getExportfileSendConfigs();
        this.getOwnoffersPlatformFtps();
    },

    mounted() {
        window.platformexportfilesendconfig = this;
    },

    methods: {
        async getOwnoffersPlatformFtps() {
            axios.get('/admin/getownoffersplatformftps')
                .then(function (response) {
                    this.ownoffersplatformftps = response.data;
                }.bind(this));
        },
        async getExportfileSendConfigs() {
            axios.get('/admin/getexportfilesendconfigsoptions')
                .then(function (response) {
                    this.exportfilecolumnsfromoptions = response.data;
                }.bind(this));
        },
        getDeletePlatformExportFileSendConfig(edit_id) {
            const _this = this;
            this.$bvModal.msgBoxConfirm('Möchten Sie diese Exportdateisendung wirklich löschen?', {
                title: 'Bitte bestätigen Sie',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value) {
                        axios.delete(this.url + edit_id, { _method: 'delete' }
                        ).then(response => {
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        getPlatformSendTypes: function () {
            axios.get('/admin/getPlatformSendTypes')
                .then(function (response) {
                    this.platformsendtypes = response.data;
                }.bind(this));
        },
        submit() {
            const _this = this;
            axios.put(this.url + this.platformexportfilesendconfig.id,
                this.platformexportfilesendconfig,
            )
                .then(response => {
                    this.showMsgBoxOne(response);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });

        },
        showMsgBoxOne(callback) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    window.location = location.protocol + "//" + location.hostname + "/admin/getplatformexportfilesendconfigs/" + this.platformexportfilesendconfig.platform_id;
                })
                .catch(err => {
                    // An error occurred
                })
        },
    },
    watch: {

    },
    computed: {
        nameState() {
            if (this.platformexportfilesendconfig.name == null || this.platformexportfilesendconfig.name == '')
                return false
            return true;
        },

        targetaddressState() {
            if (this.platformexportfilesendconfig.targetaddress == null || this.platformexportfilesendconfig.targetaddress == '')
                return false
            return true;
        },

        targetfilenameState() {
            if (this.platformexportfilesendconfig.targetfilename == null || this.platformexportfilesendconfig.targetfilename == '')
                return false;
            return true;
        },

        targetusernameState() {
            if (this.platformexportfilesendconfig.targetusername == null || this.platformexportfilesendconfig.targetusername == '')
                return false;
            return true;
        },

        targetpasswordState() {
            if (this.platformexportfilesendconfig.targetpassword == null || this.platformexportfilesendconfig.targetpassword == '')
                return false;
            return true;
        },

        delimiterState(){
            if (this.platformexportfilesendconfig.delimiter == null || this.platformexportfilesendconfig.delimiter == '')
                return false;
            return true;
        },
        decimalformatState() {
            if (this.platformexportfilesendconfig.decimalformat.trim() == '.' || this.platformexportfilesendconfig.decimalformat.trim() == ',')
                return true;
            return false;
        },
        maxmainstockState() {
            if (this.platformexportfilesendconfig.maxmainstock == null || this.platformexportfilesendconfig.maxmainstock == '')
                return false;
            return true;
        },

        validated() {
            if (this.platformexportfilesendconfig.targettype == 1 &&
                (this.platformexportfilesendconfig.targetusername == null ||
                    this.platformexportfilesendconfig.targetpassword == null) ||
                (this.platformexportfilesendconfig.targetusername == '' ||
                    this.platformexportfilesendconfig.targetpassword == '')) {
                return false;
            }
            return (
                this.platformexportfilesendconfig.name != null &&
                this.platformexportfilesendconfig.targetfilename != null &&
                (this.platformexportfilesendconfig.decimalformat.trim() == '.' || this.platformexportfilesendconfig.decimalformat.trim() == ',')
            )
        }
    },
};
</script>
