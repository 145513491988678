<template>
    <v-app>
        <v-data-table :headers="headers" :items="supplierstockshippingsurcharges" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [50, 100, 150, -1],
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Datensätze pro Seite',
            pageText: '{0}-{1} von {2}'
        }">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ editdata.country }}-Preisaufschäge des Lieferanten {{ editdata.supplierName }} </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="success" class="mx-2" fab dark small v-bind="attrs" v-on="on">
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-app-bar dark color="success">
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field v-model="editdata.supplierName" label="Lieferant*"
                                                disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="editdata.country" label="Land*"
                                                disabled></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="8">
                                            <v-text-field
                                            v-model="editedItem.surcharge"
                                            type="number"
                                            min="0"
                                            value="0"
                                            step="0.01"
                                            label="Preisaufschlag*"
                                            @input="formatNumber"
                                            required>
                                        </v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="computedSurchargeStartDateFormatted" label="Startdatum"
                                                        persistent-hint prepend-icon="event" readonly
                                                        v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="editedItem.surchargestartdate" no-title @input="menu = false"
                                                    @change="dateChanged">
                                                    <v-btn text @click="onClearSurchargeStartDate"
                                                        v-if="editedItem.surchargestartdate">Löschen</v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="computedSurchargeStopDateFormatted" label="Enddatum"
                                                        persistent-hint prepend-icon="event" readonly
                                                        v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="editedItem.surchargestopdate" no-title @input="menu2 = false"
                                                    @change="dateChanged">
                                                    <v-btn text @click="onClearSurchargeStopDate"
                                                        v-if="editedItem.surchargestopdate">Löschen</v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field label="Beschreibung" v-model="editedItem.description"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        >
                                            <v-switch
                                            v-model="editedItem.LastAction"
                                            :label="`Preisaufschlag ${editedItem.LastAction > 0 ? 'aktiviert' : 'deaktiviert'}`"
                                            color="green"
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="close">
                                    Abbrechen
                                </v-btn>
                                <v-btn color="primary" @click="save">
                                    Speichern
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">Sind Sie sicher, dass Sie diesen Preisaufschlag löschen
                                möchten?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogWarning" max-width="600px">
                        <v-card>
                            <v-card-title class="text-h5 warning">Das Startdatum darf nicht größer als Enddatum
                                sein!</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDialogWarning">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.suppliername="{ }">
                {{ editdata.supplierName }}
            </template>
            <template v-slot:item.country="{  }">
                {{ editdata.country }}
            </template>
            <template v-slot:item.description="{ item }">
                {{ item.description }}
            </template>
            <template v-slot:item.LastAction="{ item }">
                <v-switch
                v-model="item.LastAction"
                color="green"
                readonly
                ></v-switch>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="deleteItem(item)" color="red">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Löschen</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <template>
            <v-row>
                <v-col cols="3">
                    <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
                </v-col>
            </v-row>
        </template>
    </v-app>
</template>
<script>
import { drop, every, forEach, get, isArray, map, set } from 'lodash';
export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/supplierstockshippingsurcharges'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
    },

    data: vm => ({
        menu: false,
        menu2: false,
        supplierstockshippingsurcharges: [],
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        dialogWarning: false,
        rules: {
            name: [val => (val || '').length > 0 || 'Erforderlich'],
            number: [val => val > 0 || `Erforderlich`],
        },
        headers: [
            { text: 'Id', align: 'start', sortable: true, value: 'id', },
            { text: 'Lieferant', value: 'suppliername'},
            { text: 'Land', value: 'country'},
            { text: 'Preisaufschlag', value: 'surcharge' },
            { text: 'Startdatum', value: 'display_surchargestartdate' },
            { text: 'Enddatum', value: 'display_surchargestopdate' },
            { text: 'Beschreibung', value: 'description' },
            { text: 'Status', value: 'LastAction' },
            { text: 'Aktionen', value: 'actions', sortable: false },
        ],
        success: false,
        supplierstockshippingsurchargesoptions: {},
        editedItem: {
            id: 0,
            ssshippingcountries_id: vm.editdata.id,
            surcharge: 0,
            surchargestartdate: '',
            surchargestopdate: '',
            description: '',
            display_surchargestopdate: '',
            surchargestartdatemenu: false,
            display_surchargestopdate: '',
            surchargestopdatemenu: false,
            LastAction: 1,
        },
        defaultItem: {
            id: 0,
            ssshippingcountries_id: vm.editdata.id,
            surcharge: 0,
            surchargestartdate: '',
            surchargestopdate: '',
            description: '',
            display_surchargestopdate: '',
            surchargestartdatemenu: false,
            display_surchargestopdate: '',
            surchargestopdatemenu: false,
            LastAction: 1,
        },
    }),
    created() {
        this.getSupplierStockShippingSurcharges();
    },
    computed: {
        computedSurchargeStartDateFormatted() {
            return this.formatDate(this.editedItem.surchargestartdate)
        },
        computedSurchargeStopDateFormatted() {
            return this.formatDate(this.editedItem.surchargestopdate)
        },
        formTitle() {
            return this.editedItem.id === 0 ? 'Preisaufschlag hinzufügen' : 'Preisaufschlag bearbeiten'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        dialogWarning(val) {
            val || this.closeDialogWarning()
        },

        menu(val) {
            //val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        }
    },

    methods: {

        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin/supplierstocktemplateshippings/" + this.editdata.supplierstocktemplate_id;
        },
        dateChanged: function () {
            if (this.editedItem.surchargestopdate !== null && (this.editedItem.surchargestartdate >= this.editedItem.surchargestopdate)) {
                console.log("Startdatum ist größer als Enddatum");
                this.dialogWarning = true
            }
        },
        formatNumber() {
            // this.editedItem.surcharge = new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(this.editedItem.surcharge);
        },
        onClearSurchargeStartDate: function () {
            this.editedItem.surchargestartdate = null;
            this.menu = false;
        },
        onClearSurchargeStopDate: function () {
            this.editedItem.surchargestopdate = null;
            this.menu2 = false;
        },
        getSupplierStockShippingSurcharges: function () {
            axios.get('/admin/getsupplierstockshippingsurcharges/' + this.editdata.id)
                .then(function (response) {
                    this.supplierstockshippingsurcharges = response.data;
                }.bind(this));
        },

        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },

        editItem(item) {
            const _this = this;
            _this.editedItem = item;
            _this.dialog = true
        },

        deleteItem(item) {
            this.editedItem = item;
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            axios.delete(this.url + '/' + this.editedItem.id)
                .then(response => {
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            this.closeDelete();
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },

        closeDialogWarning() {
            this.dialogWarning = false
        },
        save() {
            const _this = this;
            // _this.editedItem.name = _this.getWholesalerName();
            if (this.editedItem.id > 0) {
                axios.put(this.url + '/' + this.editedItem.id,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.url,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }

            // Hide the modal manually
            this.$nextTick(() => {
                // this.resetModal();
                this.$bvModal.hide('modal-prevent-closing')
            })
            this.close()
        },

        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
            .then(value => {
                if (change) {
                    this.getSupplierStockShippingSurcharges();
                }
            })
            .catch(err => {
                // An error occurred
            })
        },
    },
}
</script>