<template>
    <v-app>
        <v-data-table 
        :headers="headers" 
        :items="shippingchargesfilemovingtasks" 
        :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [50, 100, 150, -1],
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Datensätze pro Seite',
            pageText: '{0}-{1} von {2}'
        }"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Geplante Frachten</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="success" class="mx-2" fab dark small v-bind="attrs" v-on="on">
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-app-bar dark color="success">
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-form v-model="valid"  @submit.prevent enctype="multipart/form-data">
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-select
                                                v-model="editedItem.serviceprovider_id"
                                                :items="shippingserviceproviders"
                                                item-value="id"
                                                item-text="versanddienst"
                                                label="Versanddienstleister"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-file-input
                                                v-model="file"
                                                accept="*.csv"
                                                label="Frachtdatei*"
                                                prepend-icon="mdi-cloud-upload"
                                                @change="onChangeFile"
                                                >
                                                </v-file-input>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                v-model="editedItem.filename"
                                                label="Frachtdateiname"
                                                readonly
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                    <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                            v-model="computedDateFormatted"
                                                            label="Aktivieren am"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="editedItem.date_at"
                                                            :active-picker.sync="activePicker"
                                                            min="2023-01-01"
                                                            locale="de-DE"
                                                            @input="menu = false"
                                                        ></v-date-picker>
                                                    </v-menu>
                                            </v-col>
                                            <v-col
                                            cols="11"
                                            sm="5"
                                            >
                                            <v-menu
                                                ref="menu"
                                                v-model="menu2"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="editedItem.time_at"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="editedItem.time_at"
                                                    label="Aktivieren um"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                v-if="menu2"
                                                format="24hr"
                                                v-model="editedItem.time_at"
                                                full-width
                                                @click:minute="$refs.menu.save(editedItem.time_at)"
                                                ></v-time-picker>
                                            </v-menu>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field label="Beschreibung" v-model="editedItem.description"
                                                    hide-details="auto"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-switch v-model="editedItem.lastAction"
                                                :label="`Planung ${editedItem.lastAction > 0 ? 'aktiviert' : 'deaktiviert'}`"
                                                color="green" 
                                                :false-value=0 
                                                :true-value=1
                                                ></v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="close">
                                    Abbrechen
                                </v-btn>
                                <v-btn color="primary" @click="save" v-if="editedItem.serviceprovider_id !== null && editedItem.filename !== null && editedItem.date_at !== null && editedItem.time_at !== null">
                                    Speichern
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete"  max-width="800px">
                        <v-card color="danger">
                            <v-card-title class="text-h6" color="danger">Sind Sie sicher, dass Sie diese geplannte Versandkosten löschen
                                möchten?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Nein</v-btn>
                                <v-btn color="red darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.serviceprovider_id="{ item }">
                    {{ getProviderName(item.serviceprovider_id) }}
            </template>
            <template v-slot:item.date_at="{ item }">
                {{ formatDate(item.date_at) }}
            </template>
            <template v-slot:item.time_at="{ item }">
                    {{ item.time_at }}
            </template>
            <template v-slot:item.description="{ item }">
                {{ item.description == null ? '' : item.description }}
            </template>
            <template v-slot:item.lastAction="{ item }">
                <v-tooltip bottom v-if="item.lastAction == -1">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" >
                            mdi-alert
                        </v-icon>
                    </template>
                    <span>Fehlerhaft</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.lastAction == 0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="error" v-bind="attrs" v-on="on" >
                            mdi-timer-off
                        </v-icon>
                    </template>
                    <span>Deaktiviert</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.lastAction == 1">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="info" v-bind="attrs" v-on="on" >
                            mdi-timer
                        </v-icon>
                    </template>
                    <span>Ausstehend</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.lastAction == 2">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="success" v-bind="attrs" v-on="on" >
                            mdi-check-all
                        </v-icon>
                    </template>
                    <span>Verschoben am  {{ item.moved_at }}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom v-if="item.lastAction < 2">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.lastAction < 2">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="green" v-bind="attrs" v-on="on" @click="compareItem(item)">
                            mdi-compare
                        </v-icon>
                    </template>
                    <span>Mit den aktuellen Versandkosten vergleichen</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="deleteItem(item)" color="red">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Löschen</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <template>
            <v-row>
                <v-col cols="3">
                    <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
                </v-col>
            </v-row>
        </template>
    </v-app>
</template>
<script>
export default {
    props: {
        value: Array,
        url: {
            type: String,
            default: '/admin/shippingchargesfilemovingtasks'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
    },

    data: vm => ({
        activePicker: null,
        dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        valid: false,
        menu: false,
        menu2: false,
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        headers: [
            {
                text: 'Id',
                align: 'start',
                sortable: true,
                value: 'id',
            },
            { text: 'Versanddienstleister', value: 'serviceprovider_id' },
            { text: 'Frachtdatei', value: 'filename' },
            { text: 'Aktivieren am', value: 'date_at' },
            { text: 'Aktivieren um', value: 'time_at' },
            { text: 'Aktiviert am', value: 'moved_at' },
            { text: 'Beschreibung', value: 'description' },
            { text: 'Status', value: 'lastAction' },
            { text: 'Aktionen', value: 'actions', sortable: false },
        ],
        success: false,
        shippingserviceproviders: [],
        shippingchargesfiles: [],
        shippingchargesfilemovingtasks: [],
        file: null,
        editedItem: {
            id: 0,
            serviceprovider_id: null,
            filename: null,
            date_at: null,
            time_at: null,
            moved_at: null,
            description: null,
            lastAction: 1,
        },
        defaultItem: {
            id: 0,
            serviceprovider_id: null,
            filename: null,
            date_at: null,
            time_at: null,
            moved_at: null,
            description: null,
            lastAction: 1,
        },
        boxOne: '',
    }),

    computed: {
        formTitle() {
            return this.editedItem.id === 0 ? 'Geplante Frachten hinzufügen' : 'Geplante Frachten bearbeiten'
        },
        computedDateFormatted() {
            return this.formatDate(this.editedItem.date_at)
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        date(val) {
            this.dateFormatted = this.formatDate(this.editedItem.date_at)
        },
    },

    created() {
        this.suppliertypeoptions = this.$func.getSupplierTypeOptions();
        this.getShippingChargesFileMovingTasks();
        this.getShippingChargesFilesToMove();
        this.getShippingServiceProviders();
    },

    methods: {
        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin/shippingserviceprovider";
        },
        async getShippingChargesFileMovingTasks() {
            axios.get('/admin/getshippingchargesfilemovingtasks')
                .then(function (response) {
                    this.shippingchargesfilemovingtasks = response.data;
                }.bind(this));
        },
        getProviderName(serviceprovider_id)
        {
           let serviceprovider = this.shippingserviceproviders.find(o => o.id === serviceprovider_id);
           return serviceprovider == null ? '' : serviceprovider.versanddienst;
        },
        async getShippingChargesFilesToMove() {
            axios.get('/admin/getshippingchargesfiles')
                .then(function (response) {
                    this.shippingchargesfiles = response.data;
                }.bind(this));
        },

        async getShippingServiceProviders() {
            axios.get('/admin/getshippingserviceproviders')
                .then(function (response) {
                    this.shippingserviceproviders = response.data;
                }.bind(this));
        },
        compareItem(item) {
            window.location = location.protocol + "//" + location.hostname + "/admin/viewcomparescheduledshippingservicecharges/"+ item.id;
        },

        editItem(item) {
            axios.get(this.url + '/' + item.id + '/edit')
                .then(function (response) {
                    this.editedItem = response.data;
                    this.dialog = true
                }.bind(this));
        },

        deleteItem(item) {
            axios.get(this.url + '/' + item.id + '/edit')
                .then(function (response) {
                    this.editedItem = response.data;
                    this.dialogDelete = true
                }.bind(this));
        },

        deleteItemConfirm() {
            const _this = this;
            axios.delete(this.url + '/' + this.editedItem.id)
                .then(response => {
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem; 
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },
        onChangeFile(){
            this.editedItem.filename = this.file == null ? '' : this.file.name;
        },
        save() {
            const _this = this;
            var formData = new FormData();
            formData.append('file', this.file);
            formData.append('serviceprovider_id', this.editedItem.serviceprovider_id);
            formData.append('filename', this.editedItem.filename);
            formData.append('date_at', this.editedItem.date_at);
            formData.append('time_at', this.editedItem.time_at);
            formData.append('description', this.editedItem.description);
            formData.append('lastAction', this.editedItem.lastAction);
            
            if (this.editedItem.id > 0) {
                axios.put(_this.url + '/' + _this.editedItem.id,
                    formData,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.url,
                    formData,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }
            // Hide the modal manually
            this.$nextTick(() => {
                // this.resetModal();
                this.$bvModal.hide('modal-prevent-closing')
            })
            this.close()
        },

        onChangeLastAction(callback) {
            const _this = this;
            var msg = "Achtung! Nach der Deaktivierung des Lieferanten werden alle Lieferantenvorlagen des Lieferanten " + this.editedItem.supplierName + " deaktiviert! Außerdem werden alle Bestände und Angebote des Lieferanten " + this.editedItem.supplierName + " mit gelöscht!";
            if (callback > 0) {
                msg = "Achtung! Nach der Aktivierung des Lieferanten werden alle Lieferantenvorlagen des Lieferanten " + this.editedItem.supplierName + " aktiviert!";
            }
            _this.$bvModal.msgBoxOk(msg, {
                title: 'Warnung',
                // okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                headerBgVariant: 'warning',
                footerClass: 'p-2 border-top-0',
                // centered: true
            })
                .then(value => {
                    //TODO: 30.10.2023 das Problem Uncaught InternalError: too much recursion lösen!
                })
                .catch(err => {
                    // console.log(err);
                })
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        this.getShippingChargesFileMovingTasks();
                    }
                })
                .catch(err => {
                })
        },

        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },

        parseDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`
        },
    },
}
</script>