<template>
    <div class="vue-csv-uploader">
        <div class="form">
            <div class="form">
                <b-form-group role="form-group">
                    <label for="input-name">Lieferantenname:</label>
                    <b-form-input
                    id="input-name"
                    v-model="supplierName"
                    disabled
                    ></b-form-input>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-supplierNumber">Lieferantennummer:</label>
                    <b-form-input
                    id="input-supplierNumber"
                    v-model="supplierNumber"
                    disabled
                    ></b-form-input>
                </b-form-group>
                <div class="table-responsive">
                    <!-- https://smarttutorials.net/dynamically-add-or-remove-table-row-using-vuejs/ -->
                    <button type='button' class="btn btn-info" @click="addNewRow">
                        <i class="fas fa-plus-circle"></i>
                        Land Hinzufügen
                    </button>

                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Land</th>
                                <th scope="col">Anzahl der Reifen</th>
                                <th scope="col text-right">Preis</th>
                                <th scope="col text-right">Einzelpreis</th>
                                <!--th scope="col">Type des Preisaufschlages</th>
                                <th scope="col">Preisaufschlag</th>
                                <th scope="col">Startdatum des Preisaufschlages</th>
                                <th scope="col">Enddatum des Preisaufschlages</th -->
                                <th scope="col">Beschreibung</th>
                                <th scope="col">#</th>
                            </tr>
                        </thead>
                        <tr v-for="(suppliercountry, k) in supplier_countries" :key="k">
                            <td>
                                <b-form-select
                                v-model="suppliercountry.code"
                                :options="countryoptions"
                                class="mb-5"
                                value-field="code"
                                text-field="country"
                                disabled-field="notEnabled"
                                required
                                ></b-form-select>
                            </td>
                            <td>
                                <input class="form-control" type="number" min="1" step="1" v-model="suppliercountry.parsel" required />
                            </td>
                            <td>
                                <input class="form-control" type="number" min="0" step=".01" v-model="suppliercountry.price" required />
                            </td>
                            <!--td>
                                <b-form-select
                                v-model="suppliercountry.surchargetype"
                                :options="surchargetypeoptions"
                                class="mb-3"
                                value-field="key"
                                text-field="type"
                                disabled-field="notEnabled"
                                ></b-form-select>
                            </td>
                            <td>
                                 <input class="form-control" type="number" min="0" step=".01" v-model="suppliercountry.surcharge" v-if="suppliercountry.surchargetype > 0" />
                            </td -->
                             <td>
                                 <input class="form-control" type="date"  v-model="suppliercountry.startdate"  />
                            </td>
                             <td>
                                 <input class="form-control" type="date"  v-model="suppliercountry.stopdate"/>
                            </td>
                             <td>
                                 <input class="form-control" type="text"  v-model="suppliercountry.description" />
                            </td>
                            <td scope="row" class="trashIconContainer">
                                <i class="far fa-trash-alt fa-2x" style="color: red;" @click="deleteRow(k, suppliercountry)"></i>
                            </td>
                        </tr>
                    </table>
                </div>
                <b-form-group>
                    <slot name="submit" :submit="submit">
                        <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                    </slot>
                </b-form-group>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/supplierstockshippingcountries'
            },

            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },

            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
                enableSubmit: false,
                countryoptions:{},
                supplierstocktemplate_id:0,
                supplierName: '',
                supplierNumber: '',
                supplier_countries: [{
                    supplierstocktemplate_id:0,
                    supplierName: '',
                    supplierNumber: '',
                    code: '',
                    country: '',
                    price: '',
                    oneunitprice: '',
                    parsel:0,
                    // surchargetype:0,
                    // surcharge:0.00,
                    startdate: null,
                    stopdate: null,
                    description: null
                }],

                surchargetypeoptions:[
                    {
                        key: 0,
                        type: 'Deaktiviert'
                    },
                    {
                        key: 1,
                        type: 'Aktiviert ohne Zeitfenster'
                    },
                    {
                        key: 2,
                        type: 'Aktiviert mit Zeitfenster'
                    },
                ]
        }),

        created() {

            this.getCountries();
            this.supplier_countries = [];
            this.supplierstocktemplate_id = this.editdata.id;
            this.supplierName = this.editdata.supplierName;
            this.supplierNumber = this.editdata.supplierNumber;
            this.getSupplierCountries();
            console.log('AddSupplierStockTemplateCountries');
        },

        methods: {
            getSupplierCountries: function(){
                axios.get('/admin/suppliershippingcountries/getshippingscountries/'+this.supplierstocktemplate_id)
                .then(function (response) {
                    this.supplier_countries = response.data;
                }.bind(this));
            },
            getCountries: function(){
                axios.get('/admin/getshippingcountries')
                .then(function (response) {
                    this.countryoptions = response.data;
                }.bind(this));
            },
            addNewRow() {
                this.supplier_countries.push({
                    id: 0,
                    supplierstocktemplate_id: this.supplierstocktemplate_id,
                    supplierName: this.supplierName,
                    supplierNumber: this.supplierNumber,
                    code: '',
                    country: '',
                    price: '',
                    oneunitprice:'',
                    parsel:0,
                    // surchargetype:0,
                    // surcharge:0.00,
                    // surchargestartdate: null,
                    // surchargestopdate: null,
                    description: null
                });
            },
            deleteRow(index, suppliercountry) {
                var idx = this.supplier_countries.indexOf(suppliercountry);
                console.log(idx, index);
                if (idx > -1) {
                    this.supplier_countries.splice(idx, 1);
                   if(suppliercountry.id > 0)
                   {
                       axios.delete('/admin/suppliershippingcountries/'+suppliercountry.id
                       ).then(response => {
                        // _this.callback(response);
                        console.log(response);
                        this.showMsgBoxOne(response, false);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                   }
                }
            },

            submit() {
                 const _this = this;
                axios.post(this.url,
                    this.supplier_countries,
                ).then(response => {
                   // _this.callback(response);
                   console.log(response);
                   this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if(change)
                    {
                        window.location = location.protocol + "//" + location.hostname + "/admin/supplierstocktemplate";
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },
        },
        watch: {

        },
        computed: {
            nameState() {
                if(this.name == null)
                    return false
                return this.name.length > 3 ? true : false
            },
            isocodeState(){
                if(this.isocode == null)
                    return false;

                if(this.isocode.length != 2)
                    return false
                return true;
            },
            priceState(){
                return this.price == null ? false : true
            },

        },
    };
</script>
