<template>
    <b-button size="sm" variant="outline-secondary" @click="submit">
       <b-icon icon="cloud-upload" aria-hidden="true">FTP</b-icon>
  </b-button>
    <!-- <v-app>
      <v-container> -->
        <!-- <v-btn
          :disabled="dialog"
          :loading="dialog"
          color="purple-darken-2"
          @click="dialog = true"
          size="small"
        >
          Ftptest
        </v-btn> -->
        <!-- <v-dialog
          v-model="dialog"
          :scrim="false"
          persistent
          width="auto"
        >
          <v-card
            color="primary"
          >
            <v-card-text>
              Please stand by
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog> -->
        <!-- </v-container>
    </v-app> -->
</template>
<script>
    export default {
      props: {
        ftp_protocol: {
          type: String,
          default: 'FTP'
        },
        ftp_host: {
        type: String,
        default: null
        },
        ftp_port: {
          type: Number,
          default: 21
        },
        ftp_username: {
          type: String,
          default: ''
        },
        ftp_password: {
          type: String,
          default: ''
        },
      },
        data: () => ({
          dialog: false,
          ftpconnection: {
            ftp_protocol: 'FTP',
            ftp_host: null,
            ftp_port: 21,
            ftp_username: null,
            ftp_password: null,
          }
        }),

        created() {

        },

        methods: {
                submit()
                {
                  const _this = this;
                  _this.ftpconnection.ftp_protocol = _this.ftp_protocol;
                  _this.ftpconnection.ftp_host = _this.ftp_host;
                  _this.ftpconnection.ftp_port = _this.ftp_port;
                  _this.ftpconnection.ftp_username = _this.ftp_username;
                  _this.ftpconnection.ftp_password = _this.ftp_password;
                  axios.post('/admin/checkftpconnection',
                    _this.ftpconnection,
                  ).then(response => {
                    _this.showMsgBox(response);
                  }).catch(response => {
                    // _this.catch(response);
                  }).finally(response => {
                    // _this.finally(response);
                  });
                },
                showMsgBox(response) {
                this.$bvModal.msgBoxOk(response.data['message'], {
                  title: response.data['msg_typ'],
                  size: 'lg',
                  buttonSize: 'sm',
                  okVariant: 'dark',
                  headerBgVariant: response.data['msg_typ'] == 'WARNUNG'  ? 'warning' : 'success',
                  headerTextVariant: 'white',
                  // header - text - variant="headerTextVariant",
                  headerClass: 'p-2 border-bottom-0',
                  footerClass: 'p-2 border-top-0',
                  centered: true
                })
                  .then(value => {
                   
                  })
                  .catch(err => {
                    // An error occurred
                  })
              }

        },
        watch: {

        },
        computed: {

        },
    };
</script>