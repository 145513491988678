<template>
  <v-app>
    <v-data-table
      :headers="headers"
      :items="suppliers"
      :search="search"
      :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [50, 100, 150, -1],
          itemsPerPageAllText: 'Alle',
          itemsPerPageText: 'Datensätze pro Seite',
          pageText: '{0}-{1} von {2}'
        }"
      fixed-header
      height="200vh"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Lieferanten</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
           <v-text-field
              v-model="search"
              label="Suchen"
              prepend-inner-icon="mdi-magnify"
              variant="outlined"
              hide-details
              single-line
            ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                class="mx-2"
                fab
                dark
                small
                v-bind="attrs"
                v-on="on"
              >
              <v-icon dark>
                mdi-plus
              </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-app-bar
                dark
                color="success"
              >
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              </v-app-bar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.supplierName"
                        label="Lieferantenname"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-select
                        v-model="editedItem.supplierType"
                        :items="suppliertypeoptions"
                        label="Lieferantentype*"
                        item-text="text"
                        item-value="value"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-select
                        v-model="editedItem.country"
                        :items="countriesoptions"
                        label="Land*"
                        item-text="country"
                        item-value="code"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-switch
                      v-model="editedItem.isMainSupplier"
                      label="Hauptlieferant"
                      color="green"
                      ></v-switch>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-switch
                      v-model="editedItem.isShippingMainStandard"
                      label="Standardfrachten"
                      color="blue"
                      persistent-hint
                      hint="Sind die Frachten des Lieferantes Standardfrachten?"
                      ></v-switch>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-switch
                      v-model="editedItem.isShippingFromMainStandard"
                      label="Frachten von den Standardfrachten übernehmen"
                      color="red"
                      :disabled="editedItem.isShippingMainStandard > 0"
                      true-value="1"
                      ></v-switch>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-switch
                      v-model="editedItem.is_pickup_shipping"
                      label="Abholpreis mit Fracht berechnen"
                      color="green"
                      true-value="1"
                      ></v-switch>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-switch
                      v-model="editedItem.isUseResalePrice"
                      label="Wiederverkaufspreis"
                      color="green"
                      persistent-hint
                      hint="Als Preis wird der Wert der Spalte ARVK2 der Artikelstammtabelle benutzt."
                      ></v-switch>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.offerpriority"
                        label="Angebotspriorität"
                        type="number"
                        min=0
                        prepend-icon="mdi-arrow-up-down"
                        clearable
                        hint="Die Priorität des Lieferanten bei den Angeboten. 0 ist die höchste Priorität."
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="editedItem.supplierType == 0"
                    >
                      <v-text-field
                        v-model="editedItem.pricevariance"
                        label="Preisabweichung"
                        type="number"
                        prepend-icon="mdi-percent"
                        clearable
                        hint="Bei einer Preisabweichung wird der Bestand gesperrt."
                      ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    >
                      <v-text-field
                      label="Beschreibung"
                      v-model="editedItem.description" 
                      hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-switch
                      v-model="editedItem.lastAction"
                      :label="`Lieferant ${editedItem.lastAction > 0 ? 'aktiviert': 'deaktiviert'}`"
                      color="green"
                      :false-value= 0 
                      :true-value= 1
                      @change="onChangeLastAction"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="close"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                color="primary"

                  @click="save"
                >
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Sind Sie sicher, dass Sie diesen Lieferant löschen möchten?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.supplierType="{ item }">
        {{ suppliertypeoptions[item.supplierType].text }}
      </template>
      <template v-slot:item.country="{ item }">
        {{ item.country.country }}
      </template>
      <template v-slot:item.isMainSupplier="{ item }">
        <v-switch
        v-model="item.isMainSupplier"
        color="red"
        disabled
        label=""
        ></v-switch>
      </template>
      <template v-slot:item.isShippingMainStandard="{ item }">
        <v-switch
            v-model="item.isShippingMainStandard"
            color="blue"
            disabled
            label=""
        ></v-switch>
      </template>
      <template v-slot:item.isShippingFromMainStandard="{ item }">
        <v-switch
          v-model="item.isShippingFromMainStandard"
          color="green"
          disabled
          label=""
          ></v-switch>
      </template> 
      <template v-slot:item.is_pickup_shipping="{ item }">
        <v-switch
          v-model="item.is_pickup_shipping"
          color="green"
          disabled
          label=""
          ></v-switch>
      </template>
      <template v-slot:item.isUseResalePrice="{ item }">
        <v-switch
        v-model="item.isUseResalePrice"
        color="green"
        disabled
        label=""
        ></v-switch>
      </template>
      <template v-slot:item.pricevariance="{ item }">
         {{ item.supplierType > 0 ? '' : (item.pricevariance == null ? 0 : item.pricevariance) + " %"}}
      </template>
      <template v-slot:item.lastAction="{ item }">
        <v-switch
        v-model="item.lastAction"
        color="green"
        disabled
        label=""
        ></v-switch>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="blue"
              v-bind="attrs"
              v-on="on"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="green"
            dark
            v-bind="attrs"
            v-on="on"
            @click="showSupplierStockTemplates(item)"
          >
            mdi-file-delimited
          </v-icon>
        </template>
        <span>Vorlagen der Lieferantenbestände</span>
      </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="orange"
              dark
              v-bind="attrs"
              v-on="on"
              @click="showSupplierShippingCountries(item)"
            >
            mdi-truck
            </v-icon>
          </template>
          <span>Verkaufsländer</span>
        </v-tooltip>
    <v-tooltip bottom v-if="item.supplierType == 1">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="blue"
          dark
          v-bind="attrs"
          v-on="on"
          @click="showManualPurchasingPriceConfig(item)"
        >
          mdi-file-cog
        </v-icon>
      </template>
      <span>Konfiguration der manuellen Einkaufspreise</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          @click="deleteItem(item)"
          color="red"
        >
          mdi-delete
        </v-icon>
      </template>
      <span>Löschen</span>
    </v-tooltip>
      </template>
    </v-data-table>
    </v-app>
  </template>
  <script>
  export default {
    props: {
        value: Array,
        url: {
            type: String,
            default: '/admin/suppliers'
        },
        callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
    },

    data: () => ({
      messagedialog: false,
      dialog: false,
      dialogDelete: false,
      search: '',
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'Name', value: 'supplierName' },
        { text: 'Type', value: 'supplierType' },
        { text: 'Land', value: 'country' },
        { text: 'Hauptlieferant', value: 'isMainSupplier' },
        { text: 'Hauptfracht', value: 'isShippingMainStandard' },
        { text: 'Standardfracht', value: 'isShippingFromMainStandard' }, 
        { text: 'Abholpreis mit Fracht', value: 'is_pickup_shipping' },
        { text: 'Wiederverkaufspreis', value: 'isUseResalePrice'},
        { text: 'Angebotspriorität', value: 'offerpriority'},
        { text: 'Preisabweichung', value: 'pricevariance' },
        { text: 'Beschreibung', value: 'description' }, 
        { text: 'Status', value: 'lastAction' },
        { text: 'Aktionen', value: 'actions', sortable:false },
      ],
      success:false,
      countriesoptions: [],
      suppliers: [],
      editedItem: {
        id: 0,
        supplierName: null,
        supplierType: 0,
        country: null,
        isMainSupplier: 0,
        isShippingMainStandard: 0,
        isShippingFromMainStandard: 0,
        is_pickup_shipping: 0,
        isUseResalePrice: 0,
        offerpriority: 1000,
        pricevariance: null,
        description: null,
        lastAction: 1,
      },
      defaultItem: {
        id: 0,
        supplierName: null,
        supplierType: 0,
        country: null,
        isMainSupplier: 0,
        isShippingMainStandard: 0,
        isShippingFromMainStandard: 0,
        is_pickup_shipping: 0,
        isUseResalePrice: 0,
        offerpriority: 1000,
        pricevariance: null,
        description: null,
        lastAction: 1,
      },
      boxOne: '',
    }),

    computed: {
      formTitle () {
        return this.editedItem.id === 0 ? 'Lieferant hinzufügen' : 'Lieferant bearbeiten'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.suppliertypeoptions = this.$func.getSupplierTypeOptions();
      this.getSuppliers();
      this.getCountries();
    },

    methods: {
      async getCountries() {
          axios.get('/admin/getshippingparentcountries')
              .then(function (response) { 
                  this.countriesoptions = response.data;
              }.bind(this));
      },
      async getSuppliers() {
          axios.get('/admin/getsuppliers')
              .then(function (response) { 
                  this.suppliers = response.data;
              }.bind(this));
      },

      showSupplierShippingCountries(item){
        let suppliershippingcountries_url = '/admin/supplier/'+item.id+'/suppliershippingcountries';
        window.location = location.protocol + "//" + location.hostname + suppliershippingcountries_url;
      },

      showSupplierStockTemplates(item){
        let supplierstocktemplates_url = '/admin/supplier/'+item.id+'/supplierstocktemplates';
        window.location = location.protocol + "//" + location.hostname + supplierstocktemplates_url;
      },

      showManualPurchasingPriceConfig(item)
      {
        let supplierstocktemplates_url = '/admin/supplier/'+item.id+'/manualpurchasingpriceconfigindex';
        window.location = location.protocol + "//" + location.hostname + supplierstocktemplates_url;
      },
       
      editItem (item) {
        axios.get(this.url+'/'+item.id+'/edit')
            .then(function (response) { 
                this.editedItem = response.data;
                this.dialog = true
            }.bind(this)); 
      },

      deleteItem (item) {
        axios.get(this.url+'/'+item.id+'/edit')
            .then(function (response) { 
                this.editedItem = response.data;
                this.dialogDelete = true
            }.bind(this));
      },

      deleteItemConfirm () {
        axios.delete(this.url+'/'+this.editedItem.id)
          .then(response => {
          this.showMsgBoxOne(response, true);
          }).catch(response => {
              _this.catch(response);
          }).finally(response => {
              _this.finally(response);
          });
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = this.defaultItem; //Object.assign({}, this.defaultItem)
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = this.defaultItem;//Object.assign({}, this.defaultItem)
        })
      },

      save () {
        const _this = this;
        if(this.editedItem.id > 0){
            axios.put(this.url+'/'+this.editedItem.id,
                this.editedItem,
            ).then(response => {
               _this.callback(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            axios.post(this.url,
                this.editedItem,
            ).then(response => {
                _this.callback(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }
        // Hide the modal manually
        this.$nextTick(() => {
           // this.resetModal();
            this.$bvModal.hide('modal-prevent-closing')
        })
        this.close()
      },

      onChangeLastAction(callback){
        const _this = this;
        var msg = "Achtung! Nach der Deaktivierung des Lieferanten werden alle Lieferantenvorlagen des Lieferanten " + this.editedItem.supplierName + " deaktiviert! Außerdem werden alle Bestände und Angebote des Lieferanten " + this.editedItem.supplierName + " mit gelöscht!";
        if(callback > 0){
          msg = "Achtung! Nach der Aktivierung des Lieferanten werden alle Lieferantenvorlagen des Lieferanten "+ this.editedItem.supplierName+" aktiviert!";
        }
        _this.$bvModal.msgBoxOk(msg, {
          title: 'Warnung',
          // okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          headerBgVariant:'warning',
          footerClass: 'p-2 border-top-0',
          // centered: true
        })
        .then(value => {
           //TODO: 30.10.2023 das Problem Uncaught InternalError: too much recursion lösen!
        })
        .catch(err => {
          // console.log(err);
        })
      },
      showMsgBoxOne(callback, change) {
        this.$bvModal.msgBoxOk(callback.data)
        .then(value => {
          if(change)
          {
            this.getSuppliers();
          }
        })
        .catch(err => {
        })
      },
    },
  }
</script>