<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    Versandkosten berechnen
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="text-h5">Versandkosten berechnen</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select
                                v-model="query.serviceprovider_id"
                                :items="shippingserviceproviders"
                                item-value="id"
                                item-text="versanddienst"
                                label="Versanddienstleister*"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-autocomplete
                                v-model="query.country"
                                :items="countryoptions"
                                item-value="code"
                                item-text="country"
                                label="Land*"
                                prepend-inner-icon="mdi-map-marker"
                                required
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-autocomplete
                                v-model="query.parseltyp"
                                :items="['Felgen', 'Reifen', 'Karton']"
                                label="Pakettype*">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                v-model="query.price"
                                label="Fracht*"
                                type="number"
                                required
                                ></v-text-field> 
                            </v-col>
                        </v-row>
                    </v-container>
                    <small>* pflicht Felder</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                        Schließen
                    </v-btn>
                    <slot name="submit" :submit="handleSubmit" >
                        <input type="submit" class="btn btn-success" @click.prevent="handleSubmit" value="Berechnen">
                    </slot>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
          v-model="responsedialog"
          width="800"
        >
          <v-card>
            <v-card-title class="text-h5">
              Versandkostenberechnung
            </v-card-title>

            <v-card-text>
                <v-simple-table dense>
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Id
                        </th>
                        <th class="text-left">
                            Beschreibung
                        </th>

                        <th class="text-left">
                            Startdatum
                        </th>
                        <th class="text-left">
                            Enddatum
                        </th>
                        <th class="text-left">
                            Type
                        </th>
                        <th class="text-right">
                            Wert
                        </th>
                        <th class="text-right">
                            Preis
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in surcharges"
                            :key="item.id"
                            >
                            <td>{{ item.id >0 ? item.id : '' }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.surchargetype > 0 ? item.surchargestartdate : '' }}</td>
                            <td>{{ item.surchargetype > 0 ? item.surchargestopdate : '' }}</td>
                            <td>{{ item.type }}</td>
                            <td class="text-right">{{ item.name == 'Versandkosten' ? '' : item.value }} {{ item.type == 'Prozent'?'%':(item.name == 'Versandkosten' ? '' : '€') }}</td>
                            <td class="text-right" v-if="item.name == 'Versandkosten'" ><v-chip>{{ item.value }} €</v-chip></td>
                            <td class="text-right" v-else>{{ item.type == 'Prozent' ? ((item.value * query.price) / 100) : item.value }} €</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="responsedialog = false"
              >
                Schließen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import axios from 'axios';

export default {

    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/checkshippingcosts'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },

        headers: {
            default: null
        },
        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },

        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        dialog: false,
        responsedialog: false,
        modalname: "Hersteller hinzufügen",
        headerBgVariant: 'success',
        headerTextVariant: 'light',
        disableOk: false,
        enableSubmit: false,
        countryoptions:[],
        shippingserviceproviders:[],
        surcharges:[],
        sumcharge:'',
        query: {
            serviceprovider_id: 0,
            country: null,
            parseltyp: null,
            price: null,
        },

    }),

    created() {
        this.getCountries();
        this.getShippingServiceProviders();
    },
    mounted() {
        // window.hersteller = this;
    },
    methods: {
        getCountries: function () {
            axios.get('/admin/getshippingparentcountries')
                .then(function (response) {
                    this.countryoptions = response.data;
                }.bind(this));
        },
        async getShippingServiceProviders() {
            axios.get('/admin/getshippingserviceproviders')
                .then(function (response) {
                    this.shippingserviceproviders = response.data;
                }.bind(this));
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    /* if(change)
                      {
                          window.location = location.protocol + "//" + location.hostname + "/admin/gethersteller/"+this.editdata.id;
                      }*/
                })
                .catch(err => {
                    // An error occurred
                })
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            //this.nameState = valid
            return valid
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {
            console.log('handle Submit');
            const _this = this;
            axios.post(this.url,
                this.query,
            ).then(response => {
                this.surcharges = response.data.surcharges;
                this.sumcharge = response.data.sumcharge;
                // _this.callback(response);
                console.log(response.data.surcharges);
                this.responsedialog = true;
                // this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });

            // Hide the modal manually
            // this.$nextTick(() => {
            //     this.resetModal();
            //     // this.$bvModal.hide('modal-hersteller')
            // })
        },

        checkFormValidity() {
            if (this.hersteller.number == null || this.hersteller.number == "" || this.hersteller.name == null || this.hersteller.name == "") {
                this.disableOk = true;
            } else {
                this.disableOk = false;
            }
        }
    },
    watch: {

    },
    computed: {

    },
};
</script>