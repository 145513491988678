<template>
    <v-app>
        <v-row>
            <v-col
                  cols="3"
                  sm="3"
                >
                  <v-text-field
                    v-model="versanddienst"
                    label="Versanddienstleister"
                    disabled
                  ></v-text-field>
                </v-col>
            <v-col
              cols="3"
              sm="3"
            >
              <v-text-field
                v-model="editdata.filename"
                label="Geplante Frachtendatei"
                disabled
              ></v-text-field>
            </v-col>
            <v-col
                  cols="3"
                  sm="3"
                >
                  <v-text-field
                    v-model="date_at"
                    label="Aktivierungsdatum"
                    disabled
                  ></v-text-field>
            </v-col>
            <v-col
                cols="3"
                sm="3"
            >
                <v-text-field
                v-model="editdata.time_at"
                label="Aktivierungszeit"
                disabled
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-data-table dense :headers="headers" :items="shippingcosts" item-key="id" :items-per-page="itemsPerPage"
                class="shippingcosts-table" :server-items-length="totalShippingcosts" :loading="loading"
                :options.sync="options" locale="de" :footer-props="footerProps" disable-sort group-by="ordername">
                <template v-slot:group.header="{ headers, items, isOpen, toggle }">
                    <th :colspan="headers.length" bg-color="indigo">
                        <v-icon @click="toggle">{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                        {{ items[0].country + ' ' + items[0].type }}
                    </th>
                </template>
                <template v-slot:header.country="{ header }">
                    {{ header.text }}
                    <v-menu v-model="countryfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="country ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-text-field v-model="country" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                                :autofocus="true"></v-text-field>
                            <v-btn text @click="countryfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="country = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:header.countrycode="{ header }">
                    {{ header.text }}
                    <v-menu v-model="countrycodefilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="countrycode ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-text-field v-model="countrycode" class="pa-4" type="text"
                                label="Geben Sie den Suchbegriff ein" :autofocus="true"></v-text-field>
                            <v-btn text @click="countrycodefilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="countrycode = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:header.uebergewicht="{ header }">
                    {{ header.text }}
                    <v-menu v-model="ugewichtfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="ugewicht ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-text-field v-model="ugewicht" class="pa-4" type="numeric"
                                label="Geben Sie den Suchbegriff ein" :autofocus="true"></v-text-field>
                            <v-btn text @click="ugewichtfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="ugewicht = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:header.type="{ header }">
                    {{ header.text }}
                    <v-menu v-model="anzahlfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="type ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-text-field v-model="type" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                                :autofocus="true"></v-text-field>
                            <v-btn text @click="anzahlfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="anzahl = ''; clearFilter()" small text color="primary" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:item.kg2="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg2')">
                        {{ convertToDecimal(item.kg2) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg2) }} </p>
                </template>
                <template v-slot:item.kg3="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg3')">
                        {{ convertToDecimal(item.kg3) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg3) }} </p>
                </template>
                <template v-slot:item.kg4="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg4')">
                        {{ convertToDecimal(item.kg4) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg4) }} </p>
                </template>
                <template v-slot:item.kg5="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg5')">
                        {{ convertToDecimal(item.kg5) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg5) }} </p>
                </template>
                <template v-slot:item.kg6="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg6')">
                        {{ convertToDecimal(item.kg6) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg6) }} </p>
                </template>
                <template v-slot:item.kg8="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg8')">
                        {{ convertToDecimal(item.kg8) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg8) }} </p>
                </template>
                <template v-slot:item.kg10="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg10')">
                        {{ convertToDecimal(item.kg10) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg10) }} </p>
                </template>
                <template v-slot:item.kg12="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg12')">
                        {{ convertToDecimal(item.kg12) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg12) }} </p>
                </template>
                <template v-slot:item.kg14="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg14')">
                        {{ convertToDecimal(item.kg14) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg14) }} </p>
                </template>
                <template v-slot:item.kg15="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg15')">
                          {{ convertToDecimal(item.kg15) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg15) }} </p>
                </template>
                <template v-slot:item.kg16="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg16')">
                        {{ convertToDecimal(item.kg16) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg16) }} </p>
                </template>
                <template v-slot:item.kg18="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg18')">
                        {{ convertToDecimal(item.kg18) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg18) }} </p>
                </template>
                <template v-slot:item.kg20="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg20')">
                        {{ convertToDecimal(item.kg20) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg20) }} </p>
                </template>
                <template v-slot:item.kg25="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg25')">
                        {{ convertToDecimal(item.kg25) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg25) }} </p>
                </template>
                <template v-slot:item.kg31_5="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg31_5')">
                        {{ convertToDecimal(item.kg31_5) }}
                    </v-chip>
                    <p v-else>{{ convertToDecimal(item.kg31_5) }} </p>
                </template>
                <template v-slot:item.kg40="{ item }">
                    <v-chip color="grey" text-color="white" small
                        v-if="item.id == getOrdernameGroup(item.ordername, 'kg40')">
                        {{ convertToDecimal(item.kg40) }}

                    </v-chip>
                    <p v-else-if="item.kg40 > 0">{{ convertToDecimal(item.kg40) }} </p>
                </template>
                <template v-slot:item.skg2="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg2, item.kg2)">
                        {{ convertToDecimal(item.skg2) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg2) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg3="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg3, item.kg3)">
                        {{ convertToDecimal(item.skg3) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg3) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg4="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg4, item.kg4)">
                        {{ convertToDecimal(item.skg4) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg4) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg5="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg5, item.kg5)">
                        {{ convertToDecimal(item.skg5) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg5) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg6="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg6, item.kg6)">
                        {{ convertToDecimal(item.skg6) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg6) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg8="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg8, item.kg8)">
                        {{ convertToDecimal(item.skg8) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg8) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg10="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg10, item.kg10)">
                        {{ convertToDecimal(item.skg10) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg10) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg12="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg12, item.kg12)">
                        {{ convertToDecimal(item.skg12) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg12) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg14="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg14, item.kg14)">
                        {{ convertToDecimal(item.skg14) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg14) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg15="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg15, item.kg15)">
                        {{ convertToDecimal(item.skg15) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg15) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg16="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg16, item.kg16)">
                        {{ convertToDecimal(item.skg16) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg16) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg18="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg18, item.kg18)">
                        {{ convertToDecimal(item.skg18) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg18) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg20="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg20, item.kg20)">
                        {{ convertToDecimal(item.skg20) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg20) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg25="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg25, item.kg25)">
                        {{ convertToDecimal(item.skg25) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg25) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg31_5="{ item }">
                    <v-chip color="green" text-color="white" small v-if="charge_less_than(item.skg31_5, item.kg31_5)">
                        {{ convertToDecimal(item.skg31_5) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else>
                        {{ convertToDecimal(item.skg31_5) }}
                    </v-chip>
                </template>
                <template v-slot:item.skg40="{ item }">
                    <v-chip color="green" text-color="white" small v-if="item.skg40 > 0 && charge_less_than(item.skg40, item.kg40)">
                        {{ convertToDecimal(item.skg40) }}
                    </v-chip>
                    <v-chip color="red" text-color="white" small  v-else-if="item.skg40 > 0">
                        {{ convertToDecimal(item.skg40) }}
                    </v-chip>
                </template>
            </v-data-table>
        </v-row>
        <template>
            <v-row>
                <v-col cols="3">
                    <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
                </v-col>
            </v-row>
        </template>
    </v-app>
</template>

<script>
import axios from 'axios';
import { drop, every, forEach, get, isArray, map, set, minBy } from 'lodash';
import colors from 'vuetify/lib/util/colors'

export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/shippingcosts'
        },
        location_url: {
            type: String,
            default: '/admin/shippingcosts/'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },

        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },

        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        disableOk: false,
        expanded: [],
        shippingcosts: [],
        totalShippingcosts: 0,
        itemsPerPage: 50,
        loading: true,
        conditions: [],
        filters: [],
        options: {
            page: 1,
            itemsPerPage: 50,
            sortBy: 'DESC',
            sortDesc: [false],
            mustSort: false,
            multiSort: false,
            sort: '',
            filters: null
        },
        compareitems: [],
        kreditor: '',
        versanddienst: '',
        country: '',
        countrycode: '',
        type: '',
        ugewicht: '',
        anzahl: '',
        kreditorfilter: false,
        versanddienstfilter: false,
        countryfilter: false,
        countrycodefilter: false,
        ugewichtfilter: false,
        anzahlfilter: false,
        kgheaders: [],
        date_at:'',
        headers: [
            { text: '                   ', value: 'expand', align: 'end' },
            { text: 'id', value: 'id' },
            { text: 'Kreditor', value: 'kreditor' },
            { text: 'Dienstleister', value: 'versanddienst' },
            { text: 'Land', value: 'country' },
            { text: 'ISO-Code', value: 'countrycode' },
            { text: 'Type', value: 'type' },
            { text: '2 kg', value: 'kg2' },
            { text: 'g 2 kg', value: 'skg2' },
            { text: '3 kg', value: 'kg3' },
            { text: 'g 3 kg', value: 'skg3' },
            { text: '4 kg', value: 'kg4' },
            { text: 'g 4 kg', value: 'skg4' },
            { text: '5 kg', value: 'kg5' },
            { text: 'g 5 kg', value: 'skg5' },
            { text: '6 kg', value: 'kg6' },
            { text: 'g 6 kg', value: 'skg6' },
            { text: '8 kg', value: 'kg8' },
            { text: 'g 8 kg', value: 'skg8' },
            { text: '10 kg', value: 'kg10' },
            { text: 'g 10 kg', value: 'skg10' },
            { text: '12 kg', value: 'kg12' },
            { text: 'g 12 kg', value: 'skg12' },
            { text: '14 kg', value: 'kg14' },
            { text: 'g 14 kg', value: 'skg14' },
            { text: '15 kg', value: 'kg15' },
            { text: 'g 15 kg', value: 'skg15' },
            { text: '16 kg', value: 'kg16' },
            { text: 'g 16 kg', value: 'skg16' },
            { text: '18 kg', value: 'kg18' },
            { text: 'g 18 kg', value: 'skg18' },
            { text: '20 kg', value: 'kg20' },
            { text: 'g 20 kg', value: 'skg20' },
            { text: '25 kg', value: 'kg25' },
            { text: 'g25 kg', value: 'skg25' },
            { text: '31,5 kg', value: 'kg31_5' },
            { text: 'g 31,5 kg', value: 'skg31_5' },
            { text: '40 kg', value: 'kg40' },
            { text: 'g 40 kg', value: 'skg40' },
            { text: 'Erstellt am', value: 'created_at' },
            { text: 'Aktualisiert am', value: 'updated_at' },
        ],
        footerProps: {
            'items-per-page-options': [50, 500, 1000, -1],
            'show-current-page': true,
            'show-first-last-page': true,
            disableItemsPerPage: false,
            itemsPerPageText: 'Einträge pro Seite',
            itemsPerPageAllText: 'Alle',
        }
    }),
    created() {
        this.filters = new Array();
        this.versanddienst = this.editdata.shippingserviceprovider.versanddienst;
        // this.versanddienst = this.editdata.filename.substr(this.editdata.filename.lastIndexOf("/") +1, (this.editdata.filename.lastIndexOf("_") - (this.editdata.filename.lastIndexOf("/") +1)));
        this.filter();
        this.date_at = this.formatDate(this.editdata.date_at);
    },
    mounted() {
        window.shippingcosts = this;
    },
    methods: {
        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin/shippingchargesfilemovingtasks";
        },

        getKgHeader() {
            this.kgheaders = this.headers.filter(item => item.value.startsWith("kg"));
        },
        convertToDecimal(value) {
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
        },
        getOrdernameGroup(ordername, kgnr) {
            let items = this.shippingcosts.filter(shippingcost => shippingcost.ordername === ordername && parseFloat(shippingcost[kgnr])); 
            let min = minBy(items, function (shippingcost) { return shippingcost[kgnr] * 100; });
            //console.log(min_val)
            if (min == null) {
                return 0;
            }
            return min['id'];
        },
        async clearFilter() {
            this.filter();
        },
        async getShippingcosts() {
            this.loading = true
            this.options.sort = this.options.sortBy;
            axios.get('/admin/getscheduledshippingcosts/', { params: this.options })
                .then(function (response) {
                    this.shippingcosts = response.data.data;
                    this.totalShippingcosts = response.data.total
                    this.itemsPerPage = response.data.per_page
                    this.loading = false
                    this.getOrdernameGroup('DEU_Karton', 'kg2');
                }.bind(this));
        },
        getColor(value) {
            if (value > 0) return "red"
            else return "green"
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        window.location = location.protocol + "//" + location.hostname + this.location_url;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        filter() {
            this.filters.length = 0;
            if (this.kreditor) {
                this.filters.push(new Array('tshippingcosts.kreditor', '=', this.kreditor));
            }
            if (this.versanddienst) {
                this.filters.push(new Array('tshippingcosts.versanddienst', 'LIKE', this.versanddienst + '%'));
            }
            if (this.country) {
                this.filters.push(new Array('tshippingcosts.country', 'LIKE', this.country + '%'));
            }
            if (this.countrycode) {
                this.filters.push(new Array('tshippingcosts.countrycode', 'LIKE', this.countrycode + '%'));
            }
            if (this.type) {
                this.filters.push(new Array('tshippingcosts.type', 'LIKE', this.type + '%'));
            }
            if (this.ugewicht) {
                this.filters.push(new Array('tshippingcosts.uebergewicht', '=', this.ugewicht));
            }
            this.options.filters = this.filters;
            this.options.page = 1;
            this.getShippingcosts();
        },

        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },
        charge_less_than(charge1, charge2) {
            return (charge1 - charge2 <= 0) && ((Math.abs(charge1 - charge2) == 0) ||  (Math.abs(charge1 - charge2) > Number.EPSILON));
        }
    },
    watch: {
        options(newValue, oldValue) {
            let cont = false
            if (oldValue.page !== newValue.page) cont = true
            if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
            if (oldValue.sortBy !== newValue.sortBy) cont = true
            if (oldValue.sortDesc !== newValue.sortDesc) cont = true
            if (cont) {
                this.getShippingcosts();
            }
        },
    },
    computed: {

    },
};
</script>
