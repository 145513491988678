<template>
    <v-app>
        <v-data-table :headers="headers" :items="artikels" :items-per-page="itemsPerPage"
            class="artikel-table" :server-items-length="totalartikels" :loading="loading"
            :options.sync="options" locale="de" :footer-props="footerProps" multi-sort>
            <template v-slot:header.ARAINR="{ header }">
                {{ header.text }}
                <v-menu v-model="arainrfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="arainr ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="arainr" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="arainrfilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="arainr = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.AREAN="{ header }">
                {{ header.text }}
                <v-menu v-model="areanfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="arean ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="arean" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="areanfilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="arean = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.ART24ID="{ header }">
                {{ header.text }}
                <v-menu v-model="art24idfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="art24id ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="art24id" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="art24idfilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="art24id = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.ARWG="{ header }">
                {{ header.text }}
                <v-menu v-model="arwgfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="arean ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="arwg" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="arwgfilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="arwg = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.ARHE="{ header }">
                {{ header.text }}
                <v-menu v-model="arhefilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="arhe ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="arhe" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="arhefilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="arhe = ''; clearFilter()" small text color="primary" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.ARALB="{ header }">
                {{ header.text }}
                <v-menu v-model="aralbfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="aralb ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="aralb" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="aralbfilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="arhe = ''; clearFilter()" small text color="primary" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.ARSVK="{ header }">
                {{ header.text }}
                <v-menu v-model="arsvkfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="arsvk ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="arsvk" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="arsvkfilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="arsvk = ''; clearFilter()" small text color="primary" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <!--template v-slot:header.LastAction="{ header }">
                {{ header.text }}
                <v-menu  v-model="lastactionfilter" offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon small :color="lastaction ? 'primary' : ''">
                        mdi-filter
                      </v-icon>
                    </v-btn>
                  </template>
                  <div style="background-color: white; width: 280px">
                    <v-autocomplete
                    v-model="lastaction"
                    :items="statusactions"
                    item-text="name"
                    item-value="key"
                    label="Select"
                    persistent-hint
                    single-line
                  ></v-autocomplete>
                  <v-btn
                  text
                  @click="lastactionfilter = false"
                  >
                  <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn>
                  <v-btn
                    @click="lastaction = ''; clearFilter()"
                    small
                    text
                    color="red"
                    class="ml-2 mb-2"
                  >
                  <v-icon dark>
                      mdi-filter-off
                    </v-icon>
                  </v-btn>
                    <v-btn
                      @click="filter"
                      small
                      text
                      color="green"
                      class="ml-2 mb-2"
                      fixed
                      right
                    >
                    <v-icon dark>
                      mdi-database-search
                    </v-icon>
                  </v-btn>
                  </div>
                </v-menu>
              </template-->
            <template v-slot:item.ARGW="{ item }">
                {{ convertToDecimal(item.ARGW, 'decimal') }}
            </template>
            <template v-slot:item.ARDEL="{ item }">
                {{ convertToDecimal(item.ARDEL, 'currency') }}
            </template>
            <template v-slot:item.ARVK1="{ item }">
                {{ convertToDecimal(item.ARVK1, 'currency') }}
            </template>
            <template v-slot:item.ARVK2="{ item }">
                {{ convertToDecimal(item.ARVK2, 'currency') }}
            </template>
            <template v-slot:item.ARVK3="{ item }">
                {{ convertToDecimal(item.ARVK3, 'currency') }}
            </template>
            <template v-slot:item.ARVK4="{ item }">
                {{ convertToDecimal(item.ARVK4, 'currency') }}
            </template>
            <template v-slot:item.ARVK5="{ item }">
                {{ convertToDecimal(item.ARVK5, 'currency') }}
            </template>
            <template v-slot:item.ARVK6="{ item }">
                {{ convertToDecimal(item.ARVK6, 'currency') }}
            </template>
            <template v-slot:item.ARVK7="{ item }">
                {{ convertToDecimal(item.ARVK7, 'currency') }}
            </template>
            <template v-slot:item.ARVK8="{ item }">
                {{ convertToDecimal(item.ARVK8, 'currency') }}
            </template>
            <template v-slot:item.ARVK9="{ item }">
                {{ convertToDecimal(item.ARVK9, 'currency') }}
            </template>
            <template v-slot:item.ARVK10="{ item }">
                {{ convertToDecimal(item.ARVK10, 'currency') }}
            </template>
            <template v-slot:item.ARVK11="{ item }">
                {{ convertToDecimal(item.ARVK11, 'currency') }}
            </template>
            <template v-slot:item.ARVK12="{ item }">
                {{ convertToDecimal(item.ARVK12, 'currency') }}
            </template>
            <template v-slot:item.ARVK13="{ item }">
                {{ convertToDecimal(item.ARVK13, 'currency') }}
            </template>
            <template v-slot:item.ARVK14="{ item }">
                {{ convertToDecimal(item.ARVK14, 'currency') }}
            </template>
            <template v-slot:item.ARVK15="{ item }">
                {{ convertToDecimal(item.ARVK15, 'currency') }}
            </template>
            <template v-slot:item.ARVK16="{ item }">
                {{ convertToDecimal(item.ARVK16, 'currency') }}
            </template>
            <template v-slot:item.ARVK17="{ item }">
                {{ convertToDecimal(item.ARVK17, 'currency') }}
            </template>
            <template v-slot:item.ARVK18="{ item }">
                {{ convertToDecimal(item.ARVK18, 'currency') }}
            </template>
        </v-data-table>
        <template>
            <v-row>
                <v-col cols="3">
                    <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
                </v-col>
            </v-row>
        </template>
    </v-app>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/artikels'
        },
        location_url: {
            type: String,
            default: '/admin/artikels/'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },

        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },

        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        disableOk: false,
        platformtemplates: [],
        artikels: [],
        totalartikels: 0,
        itemsPerPage: 50,
        loading: true,
        conditions: [],
        filters: [],
        statusactions:[{key: -1, name:'ARWG Fehlt'}, { key: -2, name: 'ARGW Fehlt' }, { key: -3, name: 'AREAN Fehlerhaft' },
            { key: 1, name: 'ARALB = 0' }, { key: 2, name: 'Importiert' }, { key: 3, name: 'aktualisiert' } ],
        options: {
            platform_id: 0,
            page: 1,
            itemsPerPage: 50,
            // sortBy: 'DESC',
            sortDesc: [false],
            mustSort: false,
            multiSort: false,
            sort: '',
            filters: null
        },
        arainr: '',
        arean: '',
        art24id:'',
        arwg: '',
        arhe: '',
        aralb: '',
        arsvk:'',
        lastaction: '',

        arainrfilter: false,
        areanfilter: false,
        art24idfilter: false,
        arwgfilter: false,
        arhefilter: false,
        aralbfilter: false,
        arsvkfilter: false,
        lastactionfilter: false,
        headers: [
            { text: 'id', value: 'id' },
            { text: 'ARAINR', value: 'ARAINR' },
            { text: 'AREAN', value: 'AREAN' },
            { text: 'ART24ID', value: 'ART24ID' },
            { text: 'ARWG', value: 'ARWG' },
            { text: 'ARGW', value: 'ARGW' },
            { text: 'ARHE', value: 'ARHE' },
            { text: 'ARAFPK', value: 'ARAFPK' },
            { text: 'ARDEL', value: 'ARDEL' },
            { text: 'ARALB', value: 'ARALB' },
            { text: 'ARUEGRGW', value: 'ARUEGRGW' },
            { text: 'ARUEGW', value: 'ARUEGW' },
            { text: 'ARSVK', value: 'ARSVK' },
            { text: 'ARPLB1', value: 'ARPLB1' },
            { text: 'ARPLB2', value: 'ARPLB2' },
            { text: 'ARHAN', value: 'ARHAN' },
            { text: 'ARRBRT', value: 'ARRBRT' },
            { text: 'ARQUER', value: 'ARQUER' },
            { text: 'ARZOGR', value: 'ARZOGR' },
            { text: 'ARLOAD', value: 'ARLOAD' },
            { text: 'ARINDK', value: 'ARINDK' },
            { text: 'ARDOT', value: 'ARDOT' },
            { text: 'ARNG', value: 'ARNG' },
            { text: 'ARKV', value: 'ARKV' },
            { text: 'ARDBW', value: 'ARDBW' },
            { text: 'ARLKL', value: 'ARLKL' },
            { text: 'ARCEK', value: 'ARCEK' },
            { text: 'AREURI', value: 'AREURI' },
            { text: 'ARMSKE', value: 'ARMSKE' },
            { text: 'ARSNOFL', value: 'ARSNOFL' },
            { text: 'ARFARB', value: 'ARFARB' },
            { text: 'ARRABR', value: 'ARRABR' },
            { text: 'ARRAGR', value: 'ARRAGR' },
            { text: 'AREPTI', value: 'AREPTI' },
            { text: 'ARLOZ2', value: 'ARLOZ2' },
            { text: 'ARLOK2', value: 'ARLOK2' },
            { text: 'ARNAD1', value: 'ARNAD1' },  
            { text: 'AREISLO', value: 'AREISLO' },
            { text: 'AREPDB', value: 'AREPDB' },
            { text: 'ARVK1', value: 'ARVK1' },
            { text: 'ARVK2', value: 'ARVK2' },
            { text: 'ARVK3', value: 'ARVK3' },
            { text: 'ARVK4', value: 'ARVK4' },
            { text: 'ARVK5', value: 'ARVK5' },
            { text: 'ARVK6', value: 'ARVK6' },
            { text: 'ARVK7', value: 'ARVK7' },
            { text: 'ARVK8', value: 'ARVK8' },
            { text: 'ARVK9', value: 'ARVK9' },
            { text: 'ARVK10', value: 'ARVK10' },
            { text: 'ARVK11', value: 'ARVK11' },
            { text: 'ARVK12', value: 'ARVK12' },
            { text: 'ARVK13', value: 'ARVK13' },
            { text: 'ARVK14', value: 'ARVK14' },
            { text: 'ARVK15', value: 'ARVK15' },
            { text: 'ARVK16', value: 'ARVK16' },
            { text: 'ARVK17', value: 'ARVK17' },
            { text: 'ARVK18', value: 'ARVK18' },
            { text: 'ARHKE', value: 'ARHKE' },
            { text: 'ARTIRENR', value: 'ARTIRENR' },
            { text: 'ARHWG', value: 'ARHWG' },
            { text: 'ART24MB', value: 'ART24MB' },
            { text: 'Erstellt', value: 'created_at' },
        ],
        footerProps: {
            'items-per-page-options': [50, 500, 1000, -1],
            'show-current-page': true,
            'show-first-last-page': true,
            disableItemsPerPage: false,
            itemsPerPageText: 'Einträge pro Seite',
            itemsPerPageAllText: 'Alle',
        }
    }),
    created() {
        this.filters = new Array();
    },
    mounted() {
        window.artikels = this;
    },
    methods: {
        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin";
        },
        getStatus: function(value) {
            ArtikelStatus
        },
        convertToDecimal(value, type) {
            if(type == 'currency')
            {
                return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
            }
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
                .replace("€", "")
                .trim();
        },
        async clearFilter() {
            this.filter();
        },
        async getArtikels() {
            this.loading = true
            this.options.sort = this.options.sortBy[0];
            axios.get('/admin/getartikels/', { params: this.options })
                .then(function (response) {
                    this.artikels = response.data.data;
                    this.totalartikels = response.data.total
                    this.itemsPerPage = response.data.per_page
                    this.loading = false
                }.bind(this));
        },
        getColor(value) {
            if (value > 0) return "red"
            else return "green"
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        window.location = location.protocol + "//" + location.hostname + this.location_url;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            //this.nameState = valid
            return valid
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        filter() {
            this.filters.length = 0;
            if (this.arainr) {
                this.filters.push(new Array('tartikelmain.ARAINR', '=', this.arainr));
            }
            if (this.arean) {
                this.filters.push(new Array('tartikelmain.AREAN', 'LIKE', '%'+ this.arean +'%'));
            }
            if (this.art24id) {
                this.filters.push(new Array('tartikelmain.ART24ID', 'LIKE', '%' + this.art24id + '%'));
            }
            if (this.arwg) {
                this.filters.push(new Array('tartikelmain.ARWG', '=', this.arwg));
            }
            if (this.arhe) {
                this.filters.push(new Array('tartikelmain.ARHE', '=', this.arhe));
            }
             if (this.aralb) {
                this.filters.push(new Array('tartikelmain.ARALB', '=', this.aralb));
            }
            if (this.arsvk) {
                this.filters.push(new Array('tartikelmain.ARSVK', '=', this.arsvk));
            }
            if (this.lastaction) {
                this.filters.push(new Array('tartikelmain.lastAction', '=', this.lastaction));
            }
            this.options.filters = this.filters;
            this.getArtikels();
        },
        onResize() {
            if (window.innerWidth < 769)
                this.isMobile = true;
            else
                this.isMobile = false;
        },
    },
    watch: {
        options(newValue, oldValue) {
            let cont = false
            if (oldValue.page !== newValue.page) cont = true
            if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
            if (oldValue.sortBy !== newValue.sortBy) cont = true
            if (oldValue.sortDesc !== newValue.sortDesc) cont = true
            if (cont) {
                this.getArtikels();
            }
        },
    },
    computed: {

    },
};
</script>
