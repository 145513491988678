<template>
    <v-app>
      <v-data-table
        :headers="headers"
        :items="suppliershippingcountries"
        :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            //firstIcon: 'mdi-arrow-collapse-left',
            //lastIcon: 'mdi-arrow-collapse-right',
            //prevIcon: 'mdi-minus',
           // nextIcon: 'mdi-plus',
            itemsPerPageOptions: [50, 100, 150, -1],
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Datensätze pro Seite',
            pageText: '{0}-{1} von {2}'
          }"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Verkaufsländer des Lieferantes {{ editdata.supplierName }}</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
              v-model="dialog"
              max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  class="mx-2"
                  fab
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon dark>
                  mdi-plus
                </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-app-bar
                  dark
                  color="success"
                >
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                </v-app-bar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <v-text-field
                          v-model="editdata.supplierName"
                          label="Lieferantenname"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                          <v-select
                              v-model="editedItem.countrycode"
                              :items="countriesoptions"
                              label="Verkaufsland*"
                              item-text="country"
                              item-value="code"
                          ></v-select>
                          {{ editedItem.countrycode }}
                      </v-col>
                      <v-col
                      cols="12"
                      >
                      <v-text-field
                      label="Beschreibung"
                      v-model="editedItem.description" 
                      hide-details="auto"
                      ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                          <v-switch
                          v-model="editedItem.lastAction"
                          :label="`Lieferant ${editedItem.lastAction > 0 ? 'aktiviert': 'deaktiviert'}`"
                          color="green"
                          ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    @click="close"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                  color="primary"
  
                    @click="save"
                  >
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Sind Sie sicher, dass Sie dieses Lieferland des Lieferantes  löschen möchten?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.supplier_id="{ item }">
          {{ editdata.supplierName }}
        </template>
        <template v-slot:item.countrycode="{ item }">
          {{ item.country.country }}
        </template>
        <template v-slot:item.lastAction="{ item }">
          <v-switch
              v-model="item.lastAction"
              color="green"
              disabled
              label=""
              ></v-switch>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="blue"
              v-bind="attrs"
              v-on="on"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            @click="deleteItem(item)"
            color="red"
          >
            mdi-delete
          </v-icon>
        </template>
        <span>Löschen</span>
      </v-tooltip>
        </template>
      </v-data-table>
      <template>
        <v-row>
          <v-col cols="3">
            <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
          </v-col>
        </v-row> 
      </template>
      </v-app>
    </template>
    <script>
    export default {
      props: {
          value: Array,
          editdata: null,
          url: {
              type: String,
              default: '/admin/suppliershippingcountries'
          },
          callback: {
                  type: Function,
                  default: () => ({})
              },
              catch: {
                  type: Function,
                  default: () => ({})
              },
              finally: {
                  type: Function,
                  default: () => ({})
              },
      },
  
      data: () => ({
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        headers: [
          {
            text: 'Id',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Lieferant', value: 'supplier_id' },
          { text: 'Lieferland', value: 'countrycode' },
          { text: 'Beschreibung', value: 'description' },
          { text: 'Status', value: 'lastAction' },
          { text: 'Aktionen', value: 'actions', sortable:false },
        ],
        success:false,
        countriesoptions: [],
        suppliershippingcountries: [],
        editedItem: {
          id: 0,
          supplier_id: null,
          countrycode: null,
          description: null,
          lastAction: 1,
        },
        defaultItem: {
          id: 0,
          supplier_id: null,
          countrycode: null,
          description: null,
          lastAction: 1,
        },
      }),
  
      computed: {
        formTitle () {
          return this.editedItem.id === 0 ? 'Verkaufsland hinzufügen' : 'Verkaufsland bearbeiten'
        },
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
  
      created () {
        this.editedItem.supplier_id = this.editdata.id;
        this.defaultItem.supplier_id = this.editdata.id;
        this.getShippingCountries();
        this.getSupplierShippingCountries();
      },
  
      methods: {
        back: function(){
            window.location = location.protocol + "//" + location.hostname + "/admin/suppliers/";
        },
        async getShippingCountries() {
            axios.get('/admin/getshippingcountries')
                .then(function (response) { 
                    this.countriesoptions = response.data;
                }.bind(this));
        },
        async getSupplierShippingCountries() {
            axios.get('/admin/supplier/'+this.editdata.id+'/getsuppliershippingcountries')
                .then(function (response) { 
                    this.suppliershippingcountries = response.data;
                }.bind(this));
        },
  
        showSupplierStockTemplates(item){
          let supplierstocktemplates_url = '/admin/supplier/'+item.id+'/supplierstocktemplates';
          window.location = location.protocol + "//" + location.hostname + supplierstocktemplates_url;
        },
         
        editItem (item) {
          const _this = this;
          axios.get(_this.url+'/'+item.id+'/edit')
              .then(function (response) { 
                  _this.editedItem = response.data;
                  _this.dialog = true
              }.bind(_this)); 
        },
  
        deleteItem (item) {
          const _this = this;
          axios.get(_this.url+'/'+item.id+'/edit')
              .then(function (response) { 
                  _this.editedItem = response.data;
                  _this.dialogDelete = true
              }.bind(_this));
        },
  
        deleteItemConfirm () {
          const _this = this;
          axios.delete(_this.url+'/'+ _this.editedItem.id)
            .then(response => {
              _this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
          _this.closeDelete()
        },
  
        close () {
          this.dialog = false
          this.$nextTick(() => {
            this.editedItem = this.defaultItem; //Object.assign({}, this.defaultItem)
          })
        },
  
        closeDelete () {
          this.dialogDelete = false
          this.$nextTick(() => {
            this.editedItem = this.defaultItem;//Object.assign({}, this.defaultItem)
          })
        },
  
        save () {
          const _this = this;
          if(this.editedItem.id > 0){
              axios.put(this.url+'/'+this.editedItem.id,
                  this.editedItem,
              ).then(response => {
                 _this.callback(response);
                  this.showMsgBoxOne(response, true);
              }).catch(response => {
                  _this.catch(response);
              }).finally(response => {
                  _this.finally(response);
              });
          }else{
              axios.post(this.url,
                  this.editedItem,
              ).then(response => {
                  _this.callback(response);
                  this.showMsgBoxOne(response, true);
              }).catch(response => {
                  _this.catch(response);
              }).finally(response => {
                  _this.finally(response);
              });
          }
          // Hide the modal manually
          this.$nextTick(() => {
             // this.resetModal();
              this.$bvModal.hide('modal-prevent-closing')
          })
          this.close()
        },
  
        showMsgBoxOne(callback, change) {
          this.$bvModal.msgBoxOk(callback.data)
          .then(value => {
              if(change)
              {
                  this.getSupplierShippingCountries();
              }
          })
          .catch(err => {
              // An error occurred
              console.log(err);
          })
        },
      },
    }
  </script>