var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-row',[_c('v-data-table',{staticClass:"shippingcosts-table",attrs:{"dense":"","headers":_vm.headers,"items":_vm.shippingcosts,"item-key":"id","items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalShippingcosts,"loading":_vm.loading,"options":_vm.options,"locale":"de","footer-props":_vm.footerProps,"item-class":_vm.colorRowItem,"disable-sort":"","group-by":"ordername"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":headers.length,"bg-color":"indigo"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down')+"\n            ")]),_vm._v("\n            "+_vm._s(items[0].country +' '+ items[0].type)+"            \n          ")],1)]}},{key:"header.kreditor",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.kreditor ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.kreditorfilter),callback:function ($$v) {_vm.kreditorfilter=$$v},expression:"kreditorfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.kreditor),callback:function ($$v) {_vm.kreditor=$$v},expression:"kreditor"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.kreditorfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-close\n            ")])],1),_vm._v(" "),_c('v-btn',{attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.kreditor = '',_vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-filter-off\n            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-database-search\n            ")])],1)],1)])]}},{key:"header.versanddienst",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.versanddienst ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.versanddienstfilter),callback:function ($$v) {_vm.versanddienstfilter=$$v},expression:"versanddienstfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.versanddienst),callback:function ($$v) {_vm.versanddienst=$$v},expression:"versanddienst"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.versanddienstfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-close\n            ")])],1),_vm._v(" "),_c('v-btn',{attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.versanddienst = '',_vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-filter-off\n            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-database-search\n            ")])],1)],1)])]}},{key:"header.country",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.country ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.countryfilter),callback:function ($$v) {_vm.countryfilter=$$v},expression:"countryfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.countryfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-close\n            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.country = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-filter-off\n            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-database-search\n            ")])],1)],1)])]}},{key:"header.countrycode",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.countrycode? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.countrycodefilter),callback:function ($$v) {_vm.countrycodefilter=$$v},expression:"countrycodefilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.countrycode),callback:function ($$v) {_vm.countrycode=$$v},expression:"countrycode"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.countrycodefilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-close\n            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.countrycode = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-filter-off\n            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-database-search\n            ")])],1)],1)])]}},{key:"header.uebergewicht",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.ugewicht ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.ugewichtfilter),callback:function ($$v) {_vm.ugewichtfilter=$$v},expression:"ugewichtfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"numeric","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.ugewicht),callback:function ($$v) {_vm.ugewicht=$$v},expression:"ugewicht"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.ugewichtfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-close\n            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.ugewicht = '';_vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n            mdi-filter-off\n          ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-database-search\n            ")])],1)],1)])]}},{key:"header.type",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.type ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.anzahlfilter),callback:function ($$v) {_vm.anzahlfilter=$$v},expression:"anzahlfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.anzahlfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-close\n            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.anzahl = '';_vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n            mdi-filter-off\n          ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n              mdi-database-search\n            ")])],1)],1)])]}},{key:"item.kg2",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg2'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg2))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg2))+"  ")])]}},{key:"item.kg3",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg3'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg3))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg3))+"  ")])]}},{key:"item.kg4",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg4'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg4))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg4))+"  ")])]}},{key:"item.kg5",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg5'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg5))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg5))+"  ")])]}},{key:"item.kg6",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg6'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg6))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg6))+"  ")])]}},{key:"item.kg8",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg8'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg8))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg8))+"  ")])]}},{key:"item.kg10",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg10'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg10))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg10))+"  ")])]}},{key:"item.kg12",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg12'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg12))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg12))+"  ")])]}},{key:"item.kg14",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg14'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg14))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg14))+"  ")])]}},{key:"item.kg15",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg15'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg15))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg15))+"  ")])]}},{key:"item.kg16",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg16'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg16))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg16))+"  ")])]}},{key:"item.kg18",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg18'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg18))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg18))+"  ")])]}},{key:"item.kg20",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg20'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg20))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg20))+"  ")])]}},{key:"item.kg25",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg25'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg25))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg25))+"  ")])]}},{key:"item.kg31_5",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg31_5'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n               "+_vm._s(_vm.convertToDecimal(item.kg31_5))+" \n               \n            ")]):_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg31_5))+"  ")])]}},{key:"item.kg40",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.getOrdernameGroup(item.ordername, 'kg40'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n                "+_vm._s(_vm.convertToDecimal(item.kg40))+" \n            ")]):(item.kg40 > 0)?_c('p',[_vm._v(_vm._s(_vm.convertToDecimal(item.kg40))+" ")]):_vm._e()]}},{key:"item.LastAction",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"false-value":0,"true-value":1,"color":"success"},on:{"change":function($event){return _vm.updateItem(item)}},model:{value:(item.LastAction),callback:function ($$v) {_vm.$set(item, "LastAction", $$v)},expression:"item.LastAction"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }