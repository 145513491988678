<template>
    <v-app>
        <div class="vue-csv-uploader">
            <div class="form">
                <div class="vue-csv-uploader-part-one">
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field v-model="suppliername" label="Lieferantenname" :rules="rules.suppliername"
                                required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field v-model="suppliernumber" label="Lieferantennummer" :rules="rules.suppliernumber"
                                required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="2">
                            <v-autocomplete v-model="suppliertype" :items="$func.getSupplierTypeOptions()"
                                label="Lieferantentyp"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-switch v-model="isMainSupplier" label="Hauptlieferant" color="indigo"></v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4" v-if="isMainSupplier">
                            <v-text-field v-model="mindestpreisrabat" label="Mindestpreis-Rabat %" required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-autocomplete :items="directories" v-model="directory" label="Dateipfad des Lieferantes*"
                        @change="changeDirectory">
                            <template v-slot:prepend>
                                <v-tooltip text="Tooltip">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                        icon
                                        color="green"
                                        @click="refreshSupplierDirectories"
                                        >
                                        <v-icon v-bind="props">mdi-cached</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                            </template>
                    </v-autocomplete>
                    <b-form-group role="form-group">
                        <label for="input-filename">Dateiname:</label>
                        <b-col sm="4">
                            <b-form-input id="input-filename" v-model="filename" :state="nameState"
                                aria-describedby="input-filename-help input-filename-feedback" placeholder="Stock Dateiname"
                                trim></b-form-input>
                            <b-form-invalid-feedback id="input-filename-feedback">
                                Erforderlich
                            </b-form-invalid-feedback>
                            <b-form-text id="input-filename-help">Stock Dateiname</b-form-text>
                        </b-col>
                    </b-form-group>
                    <b-form-group>
                        <label for="input-validitytime">Gültigkeit der Lieferantendaten (In Stunden)*:</label>
                        <b-col sm="2">
                            <b-form-input id="input-validitytime" type="number" v-model="validitytime"
                                :state="validitytimeState"
                                aria-describedby="input-validitytime-help input-validitytime-feedback"
                                placeholder="0"></b-form-input>
                            <b-form-invalid-feedback id="input-validitytime-feedback">
                                Erforderlich
                            </b-form-invalid-feedback>
                        </b-col>
                    </b-form-group>
                    <div class="form-group">
                        <b-form-checkbox id="checkbox-isStandardShippingCountries" v-model="isStandardShippingCountries"
                            name="checkbox-isStandardShippingCountries" value="1" unchecked-value="0">
                            Die Frachten des Lieferantes sind Standard-Frachten.
                        </b-form-checkbox>
                    </div>
                    <div class="form-group">
                        <b-form-checkbox id="checkbox-isfromstandardshipping" v-model="isfromstandardshipping"
                            name="checkbox-isfromstandardshipping" value="1" unchecked-value="0"
                            v-if="isStandardShippingCountries == 0">
                            Frachten von den Standart-Frachten übernehmen
                        </b-form-checkbox>
                    </div>
                    <div class="form-group">
                        <label>Vorlage des Lieferantes:</label>
                        <b-col sm="8">
                            <v-select :items="supplierfiles" label="Vorlage" v-model="selectedtemplatefile"></v-select>
                        </b-col>
                    </div>
                    <div class="form-group">
                        <label for="input-validitytime">Startzeile*:</label>
                        <b-col sm="2">
                            <b-form-input id="input-startrow" type="number" v-model="startRow"
                                aria-describedby="input-startRow-help input-startRow-feedback" placeholder="1"
                                min="1"></b-form-input>
                            <b-form-invalid-feedback id="input-startRow-feedback">
                                Erforderlich
                            </b-form-invalid-feedback>
                        </b-col>
                    </div>

                    <div class="form-group">
                        <slot name="next" :load="load">
                            <button type="submit" :disabled="disabledNextButton" :class="buttonClass" @click.prevent="load">
                                {{ loadBtnText }}
                            </button>
                        </slot>
                    </div>

                    {{ firstRow }}
                </div><!--End vue-csv-uploader-part-one -->
                <div class="vue-csv-uploader-part-two">
                    <div class="vue-csv-mapping">
                        <table :class="tableClass">
                            <slot name="thead">
                                <thead>
                                    <tr>
                                        <th width="20">Erforderliche Felder</th>
                                        <th width="30">Artikel-Felder</th>
                                        <th width="50">Musterfelder</th>
                                        <!--th width="50">Zuschläge</th-->
                                    </tr>
                                </thead>
                            </slot>
                            <tbody>
                                <tr v-for="(field, key) in fieldsToMap" :key="key">
                                    <td>{{ field.label }}:</td>
                                    <td>
                                        <v-autocomplete :items="pcart04mapFields" label="" v-model="artikel_preis_ColumnNr"
                                            clearable v-if="field.key == 'Preis'" item-value="key" item-text="label">
                                            <option selected>Auswählen</option>
                                        </v-autocomplete>
                                        <v-autocomplete :items="pcart04mapFields" label=""
                                            v-model="artikel_tyresInParcel_ColumnNr" clearable
                                            v-if="field.key == 'Reifen_Pro_Paket'" item-value="key" item-text="label">
                                            <option selected>Auswählen</option>
                                        </v-autocomplete>
                                    </td>
                                    <td>
                                        <select :class="tableSelectClass" :name="`csv_uploader_map_${key}`"
                                            v-model="map[field.key]">
                                            <option :value="-1">Ignorieren</option>
                                            <option v-for="(column, key) in firstRow" :key="key" :value="key">{{ column }}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <b-form-group label="Matchingsart:" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group v-model="matchingselected" :options="matchingoptions"
                            :aria-describedby="ariaDescribedby" name="plain-inline" plain></b-form-radio-group>
                    </b-form-group>
                    <b-form-tags v-model="itemvalues" :limit="limit" no-outer-focus class="mb-2"
                        v-if="matchingselected == '3'">
                        <template v-slot="{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }">
                            <b-input-group class="mb-2">
                                <b-form-input v-bind="inputAttrs" v-on="inputHandlers"
                                    placeholder="Hersteller-Artikelnummer eingeben und die mit der Eingabetaste hinzufügen"
                                    class="form-control"></b-form-input>
                                <b-input-group-append>
                                    <b-button @click="addTag()" variant="primary">Hinzufügen</b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <div class="d-inline-block" style="font-size: 1.5rem;">
                                <b-form-tag v-for="tag in tags" @remove="removeTag(tag)" :key="tag" :title="tag"
                                    :variant="tagVariant" class="mr-1">{{ tag }}</b-form-tag>
                            </div>
                        </template>
                    </b-form-tags>

                    <div class="form-group" v-if="selectedtemplatefile && sample && suppliername && suppliernumber && form.csv && filename && directory
                        && ((matchingselected == '3' && itemvalues.length > 0) || matchingselected != '3')">
                        <slot name="submit" :submit="submit">
                            <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
import { drop, every, forEach, get, isArray, map, set } from 'lodash';
import axios from 'axios';
import Papa from 'papaparse';

export default {
    props: {
        value: Array,

        url: {
            type: String,
            default: '/admin/supplierstocktemplate'
        },
        mapFields: {
            //required: true
            type: Array,
            default: () => {
                return ['Artikelnummer', 'Preis', 'Menge', 'Reifen_Pro_Paket', 'EAN']
            }
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
        parseConfig: {
            type: Object,
            default() {
                return {};
            }
        },
        headers: {
            default: null
        },
        loadBtnText: {
            type: String,
            default: "Laden"
        },
        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        autoMatchFields: {
            type: Boolean,
            default: false
        },
        autoMatchIgnoreCase: {
            type: Boolean,
            default: false
        },
        tableClass: {
            type: String,
            default: "table"
        },
        checkboxClass: {
            type: String,
            default: "form-check-input"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },
        fileMimeTypes: {
            type: Array,
            default: () => {
                return ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'text/x-csv', 'application/vnd.ms-excel', 'text/plain'];
            }
        },
        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
        canIgnore: {
            type: Boolean,
            default: false,
        }
    },

    data: () => ({
        rules: {
            age: [
                val => val < 10 || `I don't believe you!`,
            ],
            suppliernumber: [val => (val || '').length > 0 || 'Erforderlich'],
            suppliername: [val => (val || '').length > 0 || 'Erforderlich'],
        },
        form: {
            csv: null,
        },
        platformtemplatemappings: [],
        pcart04mapFields: [],
        fieldsToMap: [],
        pcart04fieldsToMap: [],
        map: {},
        hasHeaders: true,
        csv: null,
        sample: null,
        isValidFileMimeType: false,
        fileSelected: false,
        directories: [],
        directory: null,
        suppliertype: '0',
        isMainSupplier: false,
        mindestpreisrabat: 0,
        filename: '',
        supplierfiles: [],
        selectedtemplatefile: '',
        delimiter: ';',
        templatefile: null,
        file: null,
        enableSubmit: false,
        myValue: '10_Continental/stock',
        startRow: 1,
        suppliername: '',
        suppliernumber: '',
        matchingselected: '2',
        matchingoptions: [
            //{ text: 'mit Cartomak', value: '1' },
            { text: 'mit EAN', value: '2' },
            { text: 'mit Hersteller-Artikelnummern', value: '3' }
        ],
        itemvalues: [],
        limit: 3,
        preiszuschlag: null,
        artikel_preis_ColumnNr: -1,
        artikel_tyresInParcel_ColumnNr: -1,
        skontotype: 0,
        skontotypeoptions: [
            { value: 0, text: 'Kein Skonto' },
            { value: 1, text: 'Skonto als Preis' },
            { value: 2, text: 'Skonto als Prozent' }
        ],
        skonto: '0',
        validitytime: 0,
        isStandardShippingCountries: 0,
        isfromstandardshipping: 0,
        mapping_platformtemplate_id: -1,

    }),

    created() {
        this.getPlatformtemplateMappings();
        this.getArtikelColumns();
        this.getDirectories('/admin/supplierstocktemplatedirectories/1');
        this.hasHeaders = this.headers;
        if (isArray(this.mapFields)) {
            this.fieldsToMap = map(this.mapFields, (item) => {
                return {
                    key: item,
                    label: item
                };
            });
        } else {
            this.fieldsToMap = map(this.mapFields, (label, key) => {
                return {
                    key: key,
                    label: label
                };
            });
        }

        if (isArray(this.pcart04mapFields)) {
            this.pcart04fieldsToMap = map(this.pcart04mapFields, (item) => {
                return {
                    key: item,
                    label: item
                };
            });
        } else {
            this.pcart04mapFields = map(this.pcart04mapFields, (label, key) => {
                return {
                    key: key,
                    label: label
                };
            });
        }
        this.form.csv = this.buildMappedCsv();
    },

    methods: {
        getPlatformtemplateMappings: function () {
            axios.get('/admin/getplatformtemplatemappingsoptions')
                .then(function (response) {
                    this.platformtemplatemappings = response.data;
                }.bind(this));
        },
        toast(toaster, append = false) {
            this.$bvToast.toast(`Bitte selektieren Sie die Felder`, {
                title: `Toaster ${toaster}`,
                toaster: toaster,
                solid: true,
                appendToast: append
            })
        },
        changeDirectory: function (value) {
            console.log("Der Pfad wurde geändert " + value);
            this.getTemplateFiles();
        },
        changeDir() {
            console.log('Changed: ' + this.directory);
        },

        getArtikelColumns: function () {
            axios.get('/admin/getallartikelcolumns')
                .then(function (response) {
                    this.pcart04mapFields = response.data;
                }.bind(this));
        },
        getDirectories: function (url) {
            axios.get(url)
                .then(function (response) {
                    this.directories = response.data;
                }.bind(this));
        },
        refreshSupplierDirectories() {
            this.getDirectories('/admin/supplierstocktemplatedirectories/1');
        },
        getTemplateFiles: function () {
            if (this.directory == null) {
                return;
            }
            var dir = this.directory.substring(0, this.directory.indexOf('/'));
            this.supplierfiles = [];
            this.selectedtemplatefile = '';
            axios.get('/admin/supplierstocktemplatefiles/' + dir)
                .then(function (response) {
                    this.supplierfiles = response.data;
                    if (this.supplierfiles.length == 1) {
                        this.selectedtemplatefile = this.supplierfiles[0];
                        this.load();
                    }
                }.bind(this)).catch(response => {

                });
        },
        submit() {
            const _this = this;
            this.form.csv = this.buildMappedCsv();
            let csvdata = this.form.csv.shift();
            this.$emit('input', this.form.csv);
            const artikel_price = this.pcart04mapFields.find(element => element.key == this.artikel_preis_ColumnNr);
            const artikel_tyresInParcel = this.pcart04mapFields.find(element => element.key == this.artikel_tyresInParcel_ColumnNr);
            var stocktemplate = {
                'suppliername': this.suppliername,
                'suppliernumber': this.suppliernumber,
                'suppliertype': this.suppliertype,
                'isMainSupplier': this.isMainSupplier,
                'mindestpreisrabat': this.mindestpreisrabat,
                'directory': this.directory,
                'filename': this.filename,
                'templatefile': this.templatefile,
                'startRow': this.startRow,
                'delimiter': this.delimiter,
                'csvdata': csvdata,
                'matchingsoption': this.matchingselected,
                'itemsnumber': this.itemvalues,
                'artikel_preis': artikel_price == null ? null : artikel_price.label,
                'artikel_preis_ColumnNr': this.artikel_preis_ColumnNr,
                'artikel_tyresInParcel': artikel_tyresInParcel == null ? null : artikel_tyresInParcel.label,
                'artikel_tyresInParcel_ColumnNr': artikel_tyresInParcel == null ? -1 : artikel_tyresInParcel.key,
                'skontotype': this.skontotype,
                'skonto': this.skonto,
                'selectedtemplatefile': this.selectedtemplatefile,
                'validitytime': this.validitytime,
                'preiszuschlag': this.preiszuschlag,
                'templatecolumns': this.firstRow,
                'isStandardShippingCountries': this.isStandardShippingCountries,
                'isfromstandardshipping': this.isfromstandardshipping,
                'mapping_platformtemplate_id': this.mapping_platformtemplate_id
            }
            axios.post(this.url,
                stocktemplate,
            ).then(response => {
                // _this.callback(response);
                this.showMsgBoxOne(response);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        },
        buildMappedCsv() {
            const _this = this;
            let csv = this.hasHeaders ? drop(this.csv) : this.csv;
            return map(csv, (row) => {
                let newRow = {};
                forEach(_this.map, (column, field) => {
                    set(newRow, field, get(row, column) + '#' + column);
                });
                return newRow;
            });
        },
        validFileMimeType() {
            let file = this.$refs.csv.files[0];
            //const mimeType = file.type === "" ? mimeTypes.lookup(file.name) : file.type;

            if (file) {
                this.fileSelected = true;
                //this.isValidFileMimeType = this.validation ? this.validateMimeType(mimeType) : true;
                this.isValidFileMimeType = true;
            } else {
                this.isValidFileMimeType = !this.validation;
                this.fileSelected = false;
            }
        },

        load() {
            const _this = this;
            this.readFile((output) => {
                _this.sample = get(Papa.parse(output, { preview: 2, skipEmptyLines: true }), "data");
                _this.csv = get(Papa.parse(output, { skipEmptyLines: true }), "data");
            });
        },

        readFile(callback) {
            axios.get('/admin/gettemplate/' + this.selectedtemplatefile)
                .then(function (response) {
                    //Felder der CSV-Tabelle
                    callback(response.data);
                    this.toast('b-toaster-top-center');
                }.bind(this));
        },
        toggleHasHeaders() {
            this.hasHeaders = !this.hasHeaders;
        },
        makeId(id) {
            return `${id}${this._uid}`;
        },

        showMsgBoxOne(callback) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    window.location = location.protocol + "//" + location.hostname + "/admin/supplierstocktemplate";
                })
                .catch(err => {
                    // An error occurred
                })
        },
    },
    watch: {
        map: {
            deep: true,
            handler: function (newVal) {
                let hasAllKeys = Array.isArray(this.mapFields) ? every(this.mapFields, function (item) {
                    return newVal.hasOwnProperty(item);
                }) : every(this.mapFields, function (item, key) {
                    return newVal.hasOwnProperty(key);
                });

                if (hasAllKeys) {
                    this.form.csv = this.buildMappedCsv();
                    //this.submit();
                    //this.enableSubmit = true;
                } else {
                    //this.enableSubmit = false;
                }
            }
        },
        sample(newVal, oldVal) {
            if (this.autoMatchFields) {
                if (newVal !== null) {
                    this.fieldsToMap.forEach(field => {
                        newVal[0].forEach((columnName, index) => {
                            if (this.autoMatchIgnoreCase === true) {
                                if (field.label.toLowerCase().trim() === columnName.toLowerCase().trim()) {
                                    this.$set(this.map, field.key, index);
                                }
                            } else {
                                if (field.label.trim() === columnName.trim()) {
                                    this.$set(this.map, field.key, index);
                                }
                            }
                        });
                    });
                }
            }
        }
    },
    computed: {
        firstRow() {
            return get(this, "sample.0");
        },

        pcart04Row() {
            return get(this, "sample.0");
        },
        showErrorMessage() {
            return this.fileSelected && !this.isValidFileMimeType;
        },
        disabledNextButton() {
            return this.selectedtemplatefile == '';
        },
        nameState() {
            return this.filename.length > 2 ? true : false
        },
        suppliernameState() {
            return this.suppliername.length > 1 ? true : false
        },
        suppliernumberState() {
            return this.suppliernumber.length > 3 ? true : false
        },
        validitytimeState() {
            return this.validitytime > 0;
        },
    },
};
</script>
