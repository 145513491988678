var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.supplierstocksblockeditems,"footer-props":{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [50, 100, 150, -1],
        itemsPerPageAllText: 'Alle',
        itemsPerPageText: 'Datensätze pro Seite',
        pageText: '{0}-{1} von {2}'
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Blockierte Lieferantenbestände")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"success","fab":"","dark":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-plus\n                            ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._v(" "),_c('v-card',[_c('v-app-bar',{attrs:{"dark":"","color":"success"}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])])],1),_vm._v(" "),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.suppliers,"label":"Lieferant*","item-text":"supplierName","item-value":"id","rules":[_vm.rules.required],"required":""},model:{value:(_vm.editedItem.supplier_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "supplier_id", $$v)},expression:"editedItem.supplier_id"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"ARAINR*","type":"number","hide-details":"auto","rules":[_vm.rules.required, 
                                            _vm.rules.counter],"required":""},model:{value:(_vm.editedItem.ARAINR),callback:function ($$v) {_vm.$set(_vm.editedItem, "ARAINR", $$v)},expression:"editedItem.ARAINR"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Beschreibung","hide-details":"auto"},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.close}},[_vm._v("\n                                Abbrechen\n                            ")]),_vm._v(" "),(_vm.editedItem.supplier_id > 0 && _vm.editedItem.ARAINR > 0)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("\n                                Speichern\n                            ")]):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 color-red"},[_vm._v("Sind Sie sicher, dass Sie diesen blockierten Artikel löschen\n                            möchten?")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Abbrechen")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Ja")]),_vm._v(" "),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.supplier_id",fn:function(ref){
                                            var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.supplier.supplierName)+"\n        ")]}},{key:"item.actions",fn:function(ref){
                                            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                            var on = ref.on;
                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"blue"},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v("\n                        mdi-pencil\n                    ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Bearbeiten")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                            var on = ref.on;
                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v("\n                        mdi-delete\n                    ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Löschen")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }