<template>
    <v-app>
        <v-data-table :headers="headers" :items="packagingcosts" :items-per-page="itemsPerPage"
            class="packagingcosts-table" :server-items-length="total" :loading="loading" :options.sync="options"
            locale="de" :footer-props="footerProps" multi-sort>
            <template v-slot:top>
                <v-toolbar flat>
                    <!-- <v-toolbar-title>Verpackungskosten des Lieferanten {{ editdata.supplier.supplierName }}</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer> -->
                    <v-dialog v-model="dialog" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="success" class="mx-2" fab dark small v-bind="attrs" v-on="on">
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-app-bar dark color="success">
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-text-field v-model="editedItem.arwg_from" label="ARWG-Von*" required type="number" min="1" step="1" focused></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field v-model="editedItem.arwg_to" label="ARWG-Bis*" required type="number" min="1" step="1"></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field v-model="editedItem.arafpk" label="ARAFPK*" required type="number" min="1" step="1"></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field v-model="editedItem.zoll" label="Zoll" type="number" min="0" step="1"></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field v-model="editedItem.price" label="Preis*" required type="number" min="0" step=".01"></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field label="Beschreibung" v-model="editedItem.description" hide-details="auto"></v-text-field>
                                                    {{ editedItem.updated_at }}
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-switch v-model="editedItem.lastAction"
                                                        :label="`Verpackungskosten ${editedItem.lastAction > 0 ? 'aktiviert' : 'deaktiviert'}`"
                                                        color="green"></v-switch>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="close">
                                    Abbrechen
                                </v-btn>
                                <v-btn color="primary" @click="save" v-if="editedItem.arwg_from && editedItem.arwg_to && editedItem.arafpk && editedItem.price" :disabled="!valid">
                                    Speichern
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="600px">
                        <v-card>
                        <v-card-title class="text-h5 warning" >Sind Sie sicher, dass Sie diese Verpackungskosten löschen möchten?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Nein</v-btn>
                            <v-btn color="red" text @click="deleteItemConfirm">Ja löschen</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>

            <template v-slot:item.lastAction="{ item }">
                <v-switch v-model="item.lastAction" color="green" disabled label=""></v-switch>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="red" v-bind="attrs" v-on="on" @click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Löschen</span>
                </v-tooltip>
            </template>
            <template v-slot:item.price="{ item }">
                {{ convertDoubleToCurrency(item.price) }}
            </template>
        </v-data-table>
    </v-app>
</template>
<script>
import axios from 'axios';
export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/packagingcosts'
        },
        location_url: {
            type: String,
            default: '/admin/packagingcosts/'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },

        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },
    data: vm => ({
        valid: true,
        dialog: false,
        dialogDelete: false,
        disableOk: false,
        packagingcosts: [],
        total: 0,
        itemsPerPage: 50,
        loading: true,
        filters: [],
        options: {
            page: 1,
            itemsPerPage: 50,
            // sortBy: 'DESC',
            sortDesc: [false],
            mustSort: false,
            multiSort: false,
            sort: '',
            filters: null
        },

        rules: [
            value => !!value || 'Erforderlich.',
            value => (value || '').length <= 50 || 'Max 50 characters',
            value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Ungültige E-Mail!'
            },
        ],
        headers: [
            { text: 'id', value: 'id' },
            { text: 'ARWG-Von', value: 'arwg_from' },
            { text: 'ARWG-Bis', value: 'arwg_to' },
            { text: 'ARAFPK', value: 'arafpk' },
            { text: 'Zoll', value: 'zoll' },
            { text: 'Preis', value: 'price' },
            { text: 'Beschreibung', value: 'description' },
            { text: 'Status', value: 'lastAction' },
            { text: 'Erstellt am', value: 'created_at' },
            { text: 'Aktualisiert am', value: 'updated_at' },
            { text: 'Aktionen', value: 'actions', sortable: false },
        ],
        footerProps: {
            'items-per-page-options': [50, 500, 1000, -1],
            'show-current-page': true,
            'show-first-last-page': true,
            disableItemsPerPage: false,
            itemsPerPageText: 'Einträge pro Seite',
            itemsPerPageAllText: 'Alle',
        },

        editedItem: {
            id: 0,
            supplierstocktemplate_id: vm.editdata.id,
            arwg_from: null,
            arwg_to: null,
            arafpk: null,
            zoll: null,
            price: null,
            description: null,
            lastAction: 1,
        },
        defaultItem: {
            id: 0,
            supplierstocktemplate_id: vm.editdata.id,
            arwg_from: null,
            arwg_to: null,
            arafpk: null,
            zoll: null,
            price: null,
            description: null,
            lastAction: 1,
        },
    }),
    created() {

    },
    methods: {

        async getPackagingCosts() {
            this.loading = true
            this.options.sort = this.options.sortBy[0];
            axios.get('/admin/getpackagingcosts/', { params: this.options })
                .then(function (response) {
                    this.packagingcosts = response.data;
                    this.total = response.data.total
                    this.itemsPerPage = response.data.per_page
                    this.loading = false
                }.bind(this));
        },

        deleteItem(item) {
            axios.get(this.url + '/' + item.id + '/edit')
                .then(function (response) {
                    this.editedItem = response.data;
                    this.dialogDelete = true
                }.bind(this));
        },

        deleteItemConfirm() {
            const _this = this;
            axios.delete(_this.url + '/' + _this.editedItem.id)
                .then(response => {
                    _this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            this.closeDelete()
        },
        editItem(item) {
            this.editedItem = item;
            this.dialog = true
        },

        close() {
            this.dialog = false,
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },
        convertDoubleToCurrency(value) {
            return value === null ? '' : new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
        },
        save() {
            const _this = this;
            this.validate();
            if (this.editedItem.id > 0) {
                axios.put(this.url + '/' + this.editedItem.id,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.url,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
            this.close()
        },

        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        this.getPackagingCosts();
                    }
                })
                .catch(err => {
                    // An error occurred
                    console.log(err);
                })
        },
        validate() {
            this.$refs.form.validate()
        },

    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        options(newValue, oldValue) {
            let cont = false
            if (oldValue.page !== newValue.page) cont = true
            if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
            if (oldValue.sortBy !== newValue.sortBy) cont = true
            if (oldValue.sortDesc !== newValue.sortDesc) cont = true
            if (cont) {
                this.getPackagingCosts();
            }
        },
    },
    computed: {
        formTitle() {
            return this.editedItem.id === 0 ? 'Verpackungskosten hinzufügen' : 'Verpackungskosten bearbeiten'
        },
    },
}

</script>