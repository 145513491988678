<template>
    <v-app>
        <v-data-table :headers="headers" :items="schedulejobsconfigs" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [50, 100, 150, -1],
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Datensätze pro Seite',
            pageText: '{0}-{1} von {2}'
        }">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Zeitplan der Jobs </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="900px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="success" class="mx-2" fab dark small v-bind="attrs" v-on="on">
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-app-bar dark color="success">
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field v-model="editedItem.name" label="Name*" readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="editedItem.command" label="Befehl*" readonly></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-select v-model="editedItem.days" 
                                            :items="daysoptions"
                                            item-text="name"
                                            item-value="value"
                                            label="Tage der Woche*" 
                                            multiple
                                            chips
                                            required>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="editedItem.startTime"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="editedItem.startTime"
                                                    label="Aktivieren um"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                v-if="menu"
                                                format="24hr"
                                                v-model="editedItem.startTime"
                                                full-width
                                                @click:minute="$refs.menu.save(editedItem.startTime)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-menu
                                                ref="menu2"
                                                v-model="menu2"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="editedItem.endTime"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="editedItem.endTime"
                                                    label="Deaktivieren um"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                v-if="menu2"
                                                format="24hr"
                                                v-model="editedItem.endTime"
                                                full-width
                                                @click:minute="$refs.menu2.save(editedItem.endTime)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-switch v-model="editedItem.lastAction" label="Status"
                                                color="green"></v-switch>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field label="Beschreibung" v-model="editedItem.description"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="close">
                                    Abbrechen
                                </v-btn>
                                <v-btn color="primary" @click="save">
                                    Speichern
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">Sind Sie sicher, dass Sie diesen Job löschen möchten?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogWarning" max-width="600px">
                        <v-card>
                            <v-card-title class="text-h5 warning">Das Startdatum darf nicht größer als Enddatum
                                sein!</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDialogWarning">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Bearbeiten</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <template>
            <v-row>
                <v-col cols="3">
                    <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
                </v-col>
            </v-row>
        </template>
    </v-app>
</template>
<script>
import { drop, every, forEach, get, isArray, map, set } from 'lodash';
export default {
    props: {
        value: Array,
        url: {
            type: String,
            default: '/admin/schedulejobsconfigs'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
    },

    data: vm => ({
        menu: false,
        menu2: false,
        schedulejobsconfigs: [],
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        dialogWarning: false,
        rules: {
            name: [val => (val || '').length > 0 || 'Erforderlich'],
            number: [val => val > 0 || `Erforderlich`],
        },
        headers: [
            { text: 'Id', align: 'start', sortable: true, value: 'id', },
            { text: 'Name', value: 'name' },
            { text: 'Befehl', value: 'command' },
            { text: 'Tage der Woche', value: 'days' },
            { text: 'Startzeit', value: 'startTime' },
            { text: 'Endzeit', value: 'endTime' },
            { text: 'Interval', value: 'frequency' },
            { text: 'Status', value: 'lastAction' },
            { text: 'Beschreibung', value: 'description' },
            { text: 'Aktionen', value: 'actions', sortable: false },
        ],
        success: false,

        editedItem: {
            id: 0,
            name: '',
            command: null,
            days: null,
            startTime: null,
            endTime: null,
            frequency:null,
            description: '',
            lastAction: 1
        },
        defaultItem: {
            id: 0,
            name: '',
            command: null,
            days: null,
            startTime: null,
            endTime: null,
            frequency: null,
            description: '',
            lastAction: 1
        },

        daysoptions: [
            { name: "MONTAG", value: 1 },
            { name: "DIENSTAG", value: 2 },
            { name: "MITTWOCH", value: 3 },
            { name: "DONNERSTAG", value: 4 },
            { name: "FREITAG", value: 5 },
            { name: "SAMSTAG", value: 6 },
            { name: "SONNTAG", value: 0 },
        ]
    }),
    created() {
        this.getScheduleJobsConfigs();
    },
    computed: {
        computedDateFromFormatted() {
            return this.formatDate(this.editedItem.fromdate)
        },
        computedDateToFormatted() {
            return this.formatDate(this.editedItem.todate)
        },
        formTitle() {
            return this.editedItem.id === 0 ? 'Neuen Job hinzufügen' : 'Job bearbeiten'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        dialogWarning(val) {
            val || this.closeDialogWarning()
        },

        menu(val) {
            //val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        }
    },

    methods: {

        back: function () {
            // window.location = location.protocol + "//" + location.hostname + "/admin/platforms/" + this.editdata.platform.id + '/platformtemplates';
        },
        dateChanged: function () {
            if (this.editedItem.fromdate >= this.editedItem.todate) {
                console.log("Startdatum ist größer als Enddatum");
                this.dialogWarning = true
            }
        },
        onClearFromdate: function () {
            this.editedItem.fromdate = null;
            this.menu = false;
        },
        onClearTodate: function () {
            this.editedItem.todate = null;
            this.menu2 = false;
        },

        getScheduleJobsConfigs: function () {
            axios.get('/admin/getschedulejobsconfigs')
                .then(function (response) {
                    this.schedulejobsconfigs = response.data;
                }.bind(this));
        },

        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },

        editItem(item) {
            this.editedItem = item;
            this.dialog = true
        },

        deleteItem(item) {
            this.editedItem = item;
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            axios.delete(this.url + '/' + this.editedItem.id)
                .then(response => {
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            this.closeDelete();
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },

        closeDialogWarning() {
            this.dialogWarning = false
        },

        save() {
            const _this = this;
            if (this.editedItem.id > 0) {
                axios.put(this.url + '/' + this.editedItem.id,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.url,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }

            // Hide the modal manually
            this.$nextTick(() => {
                // this.resetModal();
                this.$bvModal.hide('modal-prevent-closing')
            })
            this.close()
        },

        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        this.getScheduleJobsConfigs();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
    },
}
</script>