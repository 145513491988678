<template>
    <div class="row">
        <b-modal
            id="modal-prevent-closing"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            ref="modal"
            :title="modalname"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            ok-title-html = "Speichern"
            cancel-title = "Abbrechen"
            :ok-disabled="disableOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                label="Platformvorlage"
                label-for="platform-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Platform ist pflicht" 
                >
                    <b-form-select
                    id="platform-input"
                    v-model="wholesalerplatformpricemarkdown.platformtemplate_id"
                    :options="platformtemplates"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                    :state="platformtemplateState"
                    required
                    disabled
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                label="Händler"
                label-for="wholesaler-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Händler ist pflicht" 
                >
                    <b-form-select
                    id="wholesaler-input"
                    v-model="wholesalerplatformpricemarkdown.wholesaler_id"
                    :options="wholesalers"
                    value-field="id"
                    text-field="displayname"
                    disabled-field="notEnabled"
                    :state="wholesalerState"
                    required
                    ></b-form-select>
                </b-form-group>
                
                <b-form-group
                label="Hersteller"
                label-for="hersteller-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Hersteller ist pflicht" 
                >
                    <b-form-select
                    id="hersteller-input"
                    v-model="wholesalerplatformpricemarkdown.hersteller_number"
                    :options="herstellers"
                    value-field="number"
                    text-field="name"
                    disabled-field="notEnabled"
                    required
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                label="ARWG-Von"
                label-for="arwg-from-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="ARWG-Von ist pflicht" 
                >
                    <b-form-input
                        id="arwg-from-input"
                        v-model="wholesalerplatformpricemarkdown.productgroup_from" 
                        :state="productgroupFromState"
                        type="number"
                        min="0"
                        value="0"
                        step="1" 
                        pattern="^(?:[0-9]\d*|\d)$"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="ARWG-bis"
                label-for="arwg-bis-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="ARWG-bis ist pflicht" 
                >
                    <b-form-input
                        id="arwg-bis-input"
                        v-model="wholesalerplatformpricemarkdown.productgroup_to" 
                        :state="productgroupToState"
                        type="number"
                        min="0"
                        value="0"
                        step="1" 
                        pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="Abschlag1"
                label-for="markdown1-bis-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Abschlag1 ist pflicht" 
                >
                    <b-form-input
                        id="markdown1-input"
                        v-model="wholesalerplatformpricemarkdown.markdown1" 
                        type="number"
                        min="0"
                        value="0"
                        step="0.01" 
                        pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        :state="markdown1State"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Abschlag1-Type"
                    label-for="markdown1type-input"
                    label-cols="8"
                    label-cols-lg="4"
                    invalid-feedback="Abschlag1-Type ist pflicht" 
                >
                    <b-form-select
                    id="markdown1type-input"
                    v-model="wholesalerplatformpricemarkdown.markdown1type"
                    :options="$func.getValueTypeOptions()"
                    value-field="key"
                    text-field="type"
                    disabled-field="notEnabled"
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                label="Abschlag2"
                label-for="markdown2-bis-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Abschlag2 ist pflicht" 
                >
                    <b-form-input
                        id="markdown1-input"
                        v-model="wholesalerplatformpricemarkdown.markdown2" 
                        type="number"
                        min="0"
                        value="0"
                        step="0.01" 
                        pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Abschlag2-Type"
                    label-for="markdown2type-input"
                    label-cols="8"
                    label-cols-lg="4"
                    invalid-feedback="Abschlag2-Type ist pflicht" 
                >
                    <b-form-select
                    id="markdown2type-input"
                    v-model="wholesalerplatformpricemarkdown.markdown2type"
                    :options="$func.getValueTypeOptions()"
                    value-field="key"
                    text-field="type"
                    disabled-field="notEnabled"
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                label="Beschreibung"
                label-for="description-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="Name is required" 
                >
                <b-form-input
                    id="description-input"
                    v-model="wholesalerplatformpricemarkdown.description" 
                ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
        <div class="table-responsive">
            <table class="display table table-bordered table-striped wholesalerplatformpricemarkdown-table nowrap"  width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Händlername</th>
                        <th>Händlernr.</th>
                        <th>Platformvorlage</th>
                        <th>Hersteller</th>
                        <th>ARWG-Von</th>
                        <th>ARWG-Bis</th>
                        <th>Abschlag1</th>
                        <th>Abschlag1-Type</th>
                        <th>Abschlag2</th>
                        <th>Abschlag2-Type</th>
                        <th>Beschreibung</th>
                        <th>Aktualisiert am</th>
                        <th>Erstellt am</th>
                        <th>Zustand</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
                <tfoot>
                    <tr>
                        <th>id</th>
                        <th>Händlername</th>
                        <th>Händlernr.</th>
                        <th>Platformvorlage</th>
                        <th>Hersteller</th>
                        <th>ARWG-Von</th>
                        <th>ARWG-Bis</th>
                        <th>Abschlag1</th>
                        <th>Abschlag1-Type</th>
                        <th>Abschlag2</th>
                        <th>Abschlag2-Type</th>
                        <th>Beschreibung</th>
                        <th>Aktualisiert am</th>
                        <th>Erstellt am</th>
                        <th>Zustand</th>
                        <th>Aktionen</th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
  
    export default {

        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/wholesalerplatformpricemarkdowns'
            },
            local_url: {
                type: String,
                default: '/admin/wholesalerplatformpricemarkdown/'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Abschlag hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:false,
            herstellers:{},
            wholesalers:{},
            platformtemplates:{},
            editshippingservicesurchage:{},
            onlynumberspattern : /^[0-9]*$/,
            wholesalerplatformpricemarkdown: {
                id: 0,
                wholesaler_id:0,
                platformtemplate_id: 0,
                hersteller_number: 0,
                productgroup_from: null,
                productgroup_to: null,
                markdown1: null,
                markdown1type: 'Preis',
                markdown2: null,
                markdown2type: 'Preis',
                description: null
            },
        }),

        created() {
            this.getHerstellers();
            this.getPlatformtemplates();
            this.getWholesalers();
            this.getWholesalerPlatformPricemarkdown('/admin/getwholesalerplatformpricemarkdowns/'+this.editdata.id);
            this.wholesalerplatformpricemarkdown.platformtemplate_id = this.editdata.id;
        },
        mounted() {
            window.wholesalerplatformpricemarkdowns=this;
        },
        methods: {
                async getWholesalerPlatformPricemarkdown(address) {
                 $(document).ready(function() {
                    var table = $('.wholesalerplatformpricemarkdown-table').DataTable({
                        autoWidth: false,
                        processing: true,
                        serverSide: false,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 100,
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        columnDefs: [
                            { className: "dt-left", "targets": "_all" },
                            {
                                width: 1,
                                targets: 0
                            }
                        ],
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'wholesalername', name: 'wholesalername'},
                            {data: 'wholesalernr', name: 'wholesalernr'},
                            {data: 'platformtemplate', name: 'platformtemplate'},
                            {data: 'hersteller', name: 'hersteller' },
                            {data: 'productgroup_from', name: 'productgroup_from' },
                            {data: 'productgroup_to', name: 'productgroup_to' },
                            {data: 'markdown1', name: 'markdown1' },
                            {data: 'markdown1type', name: 'markdown1type' },
                            {data: 'markdown2', name: 'markdown2' },
                            {data: 'markdown2type', name: 'markdown2type' },
                            {data: 'description', name: 'description' },
                            { data: 'updated_at', name: 'updated_at' },
                            { data: 'created_at', name: 'created_at'},
                            {data: 'status', name: 'status' },
                            {data: 'action', name: 'action'},
                        ],
                        initComplete: function () {
                        this.api()
                            .columns()
                            .every(function () {
                                let column = this;
                                let title = column.header().textContent;
                                // Create input element
                                let input = document.createElement('input');
                                input.placeholder = title;
                                column.header().replaceChildren(input);
                                // Event listener for user input
                                input.addEventListener('keyup', () => {
                                    if (column.search() !== this.value) {
                                        column.search(input.value).draw();
                                    }
                                });
                            });
                    }
                    });
                });
            },

            getHerstellers: function(){
                axios.get('/admin/getallhersteller')
                .then(function (response) {
                    this.herstellers = response.data;
                }.bind(this));
            },

            getPlatformtemplates: function(){
                axios.get('/admin/getallplatformtemplates')
                .then(function (response) {
                    this.platformtemplates = response.data;
                }.bind(this));
            },

            getWholesalers: function(){
                axios.get('/admin/getWholesalers')
                .then(function (response) {
                    this.wholesalers = response.data;
                    this.wholesalers.shift();
                }.bind(this));
               
            },

            getEditWholesalerPlatformPricemarkdown(edit_id){
                axios.get('/admin/wholesalerplatformpricemarkdowns/'+edit_id+'/edit')
                .then(function (response) { 
                    this.$bvModal.show('modal-prevent-closing');
                    this.modalname = "Abschlag bearbeiten"
                    this.wholesalerplatformpricemarkdown = response.data;
                    this.headerBgVariant = 'primary';
                   // this.$root.$emit("bv::show::modal", "your-modal-id");
                }.bind(this));
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   if(change)
                    {
                       //window.location = location.protocol + "//" + location.hostname + this.local_url + this.editdata.id+'#';
                       $('.wholesalerplatformpricemarkdown-table').DataTable().ajax.reload();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },
            getDeleteWholesalerPlatformPricemarkdown(edit_id){
                const _this = this;
                this.$bvModal.msgBoxConfirm('Möchten Sie diesen Preisabschlag wirklich löschen??', {
                title: 'WARNUNG',
                size: 'md',
                buttonSize: 'sm',
                bgVariant: 'danger',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.delete(this.url+'/'+edit_id
                        ).then(response => {
                            // _this.callback(response);
                            console.log(response);
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                        }
                })
                .catch(err => {
                    // An error occurred
                })
            },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        //this.nameState = valid
        return valid
      },
      resetModal() {
        this.headerBgVariant = 'success';
        this.modalname = "Neuen Preisabschlag hinzufügen";
        this.wholesalerplatformpricemarkdown.id = 0;
        this.wholesalerplatformpricemarkdown.wholesaler_id = 0;
        this.wholesalerplatformpricemarkdown.platformtemplate_id = this.editdata.id;
        this.wholesalerplatformpricemarkdown.hersteller_id = 0;
        this.wholesalerplatformpricemarkdown.productgroup_from = null;
        this.wholesalerplatformpricemarkdown.productgroup_to = null;
        this.wholesalerplatformpricemarkdown.markdown1 = null;
        this.wholesalerplatformpricemarkdown.markdown1type = 'Preis';
        this.wholesalerplatformpricemarkdown.markdown2 = null;
        this.wholesalerplatformpricemarkdown.markdown2type = 'Preis';
        this.wholesalerplatformpricemarkdown.description = null;
      },
      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
         const _this = this;
        if(this.wholesalerplatformpricemarkdown.id > 0){
            axios.put(this.url+'/'+this.wholesalerplatformpricemarkdown.id,
                this.wholesalerplatformpricemarkdown,
            ).then(response => {
            // _this.callback(response);
            console.log(response);
            this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            console.log(this.wholesalerplatformpricemarkdown);
            axios.post(this.url,
                this.wholesalerplatformpricemarkdown,
            ).then(response => {
                // _this.callback(response);
                console.log(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }

        // Hide the modal manually
        this.$nextTick(() => {
            this.resetModal();
            this.$bvModal.hide('modal-prevent-closing')
        })
      }
        },
        watch: {

        },
        computed: {
            wholesalerState(){
                return !(this.wholesalerplatformpricemarkdown.wholesaler_id == 0);
            },

            platformtemplateState(){
                return this.wholesalerplatformpricemarkdown.platformtemplate_id == 0 ? false : true;
            },

            producttypeState() {
                return (this.shippingservicesurchage.producttype == null || this.shippingservicesurchage.producttype == "") ? false : true;
            },

            productgroupFromState() {
               /* if(this.wholesalerplatformpricemarkdown.productgroup_from == null)
                {
                    return false;
                }
                return this.wholesalerplatformpricemarkdown.productgroup_from.match(this.onlynumberspattern);
                */
                return this.wholesalerplatformpricemarkdown.productgroup_from == null ? false : true;
            },

            productgroupToState() {
                
                return this.wholesalerplatformpricemarkdown.productgroup_to == null ? false : true;
            },

            markdown1State(){
                return (this.wholesalerplatformpricemarkdown.markdown1 == null || this.wholesalerplatformpricemarkdown.markdown1 == "") ? false : true;;
            }
        },
    };
</script>
