<template>
    <v-app>
        <v-data-table :headers="headers" :items="artikeltypes" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [50, 100, 150, -1],
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Datensätze pro Seite',
            pageText: '{0}-{1} von {2}'
        }">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Artikeltypen</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="success" class="mx-2" fab dark small v-bind="attrs" v-on="on">
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-app-bar dark color="success">
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedItem.name"
                                                label="Name"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field label="Beschreibung" v-model="editedItem.description"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="close">
                                    Abbrechen
                                </v-btn>
                                <v-btn color="primary" @click="save">
                                    Speichern
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">
                                Sind Sie sicher, dass Sie diesen Artikeltype löschen möchten?
                            </v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="green" dark v-bind="attrs" v-on="on" @click="showArtikelTypeGroups(item)">
                            mdi-window-shutter-cog
                        </v-icon>
                    </template>
                    <span>Warengruppen des Artikeltypes {{ item.name }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="deleteItem(item)" color="red">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Löschen</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </v-app>
</template>
<script>
export default {
    props: {
        value: Array,
        url: {
            type: String,
            default: '/admin/artikeltypes'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
    },

    data: () => ({
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        headers: [
            {
                text: 'Id',
                align: 'start',
                sortable: true,
                value: 'id',
            },
            { text: 'Name', value: 'name' },
            { text: 'Beschreibung', value: 'description' },
            { text: 'Aktionen', value: 'actions', sortable: false },
        ],
        success: false,
        countriesoptions: [],
        artikeltypes: [],
        editedItem: {
            id: 0,
            name: null,
            description: null,
        },
        defaultItem: {
            id: 0,
            name: null,
            description: null,
        },
        boxOne: '',
    }),

    computed: {
        formTitle() {
            return this.editedItem.id === 0 ? 'Artikeltype hinzufügen' : 'Artikeltype bearbeiten'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.getArtikelTypes();
    },

    methods: {
        async getArtikelTypes() {
            console.log('Call getArtikelTypes');
            axios.get('/admin/getartikeltypes')
                .then(function (response) {
                    this.artikeltypes = response.data;
                }.bind(this));
        },

        showArtikelTypeGroups(item) {
            let artikeltypegroups_url = '/admin/artikeltype/' + item.id + '/artikeltypegroups';
            window.location = location.protocol + "//" + location.hostname + artikeltypegroups_url;
        },

        editItem(item) {
            axios.get(this.url + '/' + item.id + '/edit')
                .then(function (response) {
                    this.editedItem = response.data;
                    this.dialog = true
                }.bind(this));
        },

        deleteItem(item) {
            axios.get(this.url + '/' + item.id + '/edit')
                .then(function (response) {
                    this.editedItem = response.data;
                    this.dialogDelete = true
                }.bind(this));
        },

        deleteItemConfirm() {
            const _this = this;
            axios.delete(_this.url + '/' + _this.editedItem.id)
                .then(response => {
                    _this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem; //Object.assign({}, this.defaultItem)
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;//Object.assign({}, this.defaultItem)
            })
        },

        save() {
            const _this = this;
            if (this.editedItem.id > 0) {
                axios.put(this.url + '/' + this.editedItem.id,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.url,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }
            // Hide the modal manually
            this.$nextTick(() => {
                // this.resetModal();
                this.$bvModal.hide('modal-prevent-closing')
            })
            this.close()
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        this.getArtikelTypes();
                    }
                })
                .catch(err => {
                })
        },
    },
}
</script>