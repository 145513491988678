<template>
    <v-app>
      <v-data-table
        :headers="headers"
        :items="manualpurchasingpriceartikelfilters"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [50, 100, 150, -1],
          itemsPerPageAllText: 'Alle',
          itemsPerPageText: 'Datensätze pro Seite',
          pageText: '{0}-{1} von {2}'
        }"
      >
          <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Filter der manuellen Einkaufspreise des Lieferantes {{ editdata.supplier.supplierName }} </v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
              v-model="dialog"
              max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  class="mx-2"
                  fab
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon dark>
                  mdi-plus
                </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-app-bar
                  dark
                  color="success"
                >
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                </v-app-bar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="6"
                      >
                        <v-text-field
                          v-model="editdata.supplier.supplierName"
                          label="Lieferant*"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="4"
                      >
                        <v-autocomplete
                            v-model="editedItem.artikelcolumn"
                            label="Artikel-Spalte*"
                            :items="artikelcolumns"
                            item-text="name"
                            item-value="name"
                        ></v-autocomplete>
                        {{ editedItem.artikelcolumn }}
                      </v-col>
                     
                      <v-col
                        cols="4"
                      >
                          <v-autocomplete
                              v-model="editedItem.condition"
                              label="Bedingung*"
                              :items="conditionoptions"
                              item-text="name"
                              item-value="name"
                              required
                          ></v-autocomplete>
                      </v-col>
                      <v-col
                        cols="4"
                      >
                        <v-text-field
                            v-model="editedItem.artikelcolumnvalue"
                            label="Vergleichswert*"
                            required
                        ></v-text-field>
                      </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                        cols="12"
                        >
                        <v-text-field
                        label="Beschreibung"
                        v-model="editedItem.description" 
                        hide-details="auto"
                        ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <v-switch
                            v-model="editedItem.lastAction"
                            label="Status"
                            color="green"
                            ></v-switch>
                        </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    @click="close"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                  color="primary"
                    @click="save"
                  >
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="800px">
              <v-card>
                <v-card-title class="text-h5 warning">Sind Sie sicher, dass Sie diese Konfiguration löschen möchten?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                  <v-btn color="red darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!--v-dialog v-model="dialogWarning" max-width="600px">
              <v-card>
                <v-card-title class="text-h5 warning">Das Startdatum darf nicht größer als Enddatum sein!</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDialogWarning">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog-->
          </v-toolbar>
        </template>
        <template v-slot:item.smpurchasingprice_id="{ item }">
          {{ editdata.supplier.supplierName}}
        </template>
        <template v-slot:item.exportfilename="{ item }">
          {{ editdata.exportfilename}}
        </template>
        <!--template v-slot:item.artikelcolumn="{ item }">
          {{ getArtikelColumnName(item.artikelcolumn) }}
        </template-->
        <template v-slot:item.lastAction="{ item }">
            <v-switch
            v-model="item.lastAction"
            color="green"
            disabled
            label=""
            ></v-switch>
      </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="blue"
              v-bind="attrs"
              v-on="on"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
        <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            @click="deleteItem(item)"
            color="red"
          >
            mdi-delete
          </v-icon>
        </template>
        <span>Löschen</span>
      </v-tooltip>  
      </template>
      </v-data-table>
        <template>
          <v-row>
            <v-col cols="3">
              <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
            </v-col>
          </v-row> 
        </template>
      </v-app>
  </template>
  <script>
   import { drop, every, forEach, get, isArray, map, set } from 'lodash';
    import axios from 'axios';
    export default {
      props: {
          value: Array,
          editdata: null,
          url: {
              type: String,
              default: '/admin/manualpurchasingpriceartfilters'
          },
          callback: {
                  type: Function,
                  default: () => ({})
              },
              catch: {
                  type: Function,
                  default: () => ({})
              },
              finally: {
                  type: Function,
                  default: () => ({})
              },
      },
  
      data: vm => ({
        menu: false,
        menu2: false,
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        dialogWarning: false,
        rules: {
            name: [val => (val || '').length > 0 || 'Erforderlich'],
            number: [val => val > 0 || `Erforderlich`],
          },
        headers: [
          {
            text: 'Id',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Lieferant', value: 'smpurchasingprice_id' },
          { text: 'Dateiname', value: 'exportfilename' },
          { text: 'Spalte', value: 'artikelcolumn' },
          { text: 'Bedingung', value: 'condition' },
          { text: 'Vergleichswert', value: 'artikelcolumnvalue' },
          { text: 'Beschreibung', value: 'description' },
          { text: 'Status', value: 'lastAction' },
          { text: 'Aktionen', value: 'actions', sortable:false },
        ],
        success:false,
        artikelcolumns:[],
        manualpurchasingpriceartikelfilters:[],
        conditionoptions:[ {key: '1', name: '='},{key: '2', name: '>'},{key: '3', name: '>='}, {key: '4', name: '<'}, {key: '5', name: '<='},],
        editedItem: {
          id:0,
          smpurchasingprice_id: vm.editdata.id,
          artikelcolumn: null,
          condition: null,
          artikelcolumnvalue: null,
          description: '',
          lastAction:1
        },
        defaultItem: {
          id:0,
          smpurchasingprice_id: vm.editdata.id,
          artikelcolumn: null,
          condition: null,
          artikelcolumnvalue: null,
          description: '',
          lastAction:1
        },
      }),
      created () {
        this.getAllArtikelColumns();
        this.getManualPurchasingPriceArtikelFilters();
      },
      computed: {
        formTitle () {
          return this.editedItem.id === 0 ? 'Filter der manuellen Einkaufspreise hinzufügen' : 'Filter der manuellen Einkaufspreise bearbeiten'
        },
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
        dialogWarning (val){
          val || this.closeDialogWarning()
        },
        
        menu (val) {
          //val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        }
      },

      methods: {
        back: function(){ 
          window.location = location.protocol + "//" + location.hostname + "/admin/supplier/"+this.editdata.supplier_id+"/manualpurchasingpriceconfigindex";
        },
        getArtikelColumnName: function(columnnr){
          let column = get(this.artikelcolumns, [columnnr - 1]);
          return column["name"];
        },
        getManualPurchasingPriceArtikelFilters: function(){
            axios.get('/admin/manualpurchasingpriceconfig/'+this.editdata.id+'/getmanualpurchasingpriceartikelfilters')
            .then(function (response) {
                this.manualpurchasingpriceartikelfilters = response.data;
                console.log(response);
            }.bind(this));
        },

        getAllArtikelColumns: function(){
            const _this = this;
            axios.get('/admin/getallartikelcolumns')
            .then(function (response) {
                $.each(response.data, function(key, value) {
                    if(value.key > -1){
                        _this.artikelcolumns.push({
                          id: value.key,
                          name: value.label
                      });
                    }
                });
            }.bind(this));
        },

        editItem (item) {
          this.editedItem = item;
          this.dialog = true
        },
  
        deleteItem (item) {
          this.editedItem = item;
          this.dialogDelete = true
        },
  
        deleteItemConfirm () {
          axios.delete(this.url+'/'+this.editedItem.id)
            .then(response => {
            this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
          this.closeDelete();
        },
  
        close () {
          this.dialog = false
          this.$nextTick(() => {
            this.editedItem = this.defaultItem; 
          })
        },
  
        closeDelete () {
          this.dialogDelete = false
          this.$nextTick(() => {
            this.editedItem = this.defaultItem;
          })
        },

        closeDialogWarning () {
          this.dialogWarning = false
        },
  
        save () {
          const _this = this;
          console.log('Id: '+this.editedItem.id);
          if(this.editedItem.id > 0){
              axios.put(this.url+'/'+this.editedItem.id,
                  this.editedItem,
              ).then(response => {
                 _this.callback(response);
                  this.showMsgBoxOne(response, true);
              }).catch(response => {
                  _this.catch(response);
              }).finally(response => {
                  _this.finally(response);
              });
          }else{
              axios.post(this.url,
                  this.editedItem,
              ).then(response => {
                  _this.callback(response);
                  this.showMsgBoxOne(response, true);
              }).catch(response => {
                  _this.catch(response);
              }).finally(response => {
                  _this.finally(response);
              });
          }
  
          // Hide the modal manually
          this.$nextTick(() => {
             // this.resetModal();
              this.$bvModal.hide('modal-prevent-closing')
          })
          this.close()
        },
  
        showMsgBoxOne(callback, change) {
          this.$bvModal.msgBoxOk(callback.data)
          .then(value => {
              if(change)
              {
                this. getManualPurchasingPriceArtikelFilters();
              }
          })
          .catch(err => {
              // An error occurred
          })
        },
      },
    }
  </script>