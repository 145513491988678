var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-row',[_c('v-data-table',{staticClass:"platformownoffers-table",attrs:{"headers":_vm.headers,"items":_vm.platformownoffers,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalPlatformownoffers,"loading":_vm.loading,"options":_vm.options,"locale":"de","footer-props":_vm.footerProps,"multi-sort":"","loading-text":"Wird geladen...","fixed-header":"","height":"200vh"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.platformname",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.platform ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.platformfilter),callback:function ($$v) {_vm.platformfilter=$$v},expression:"platformfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-autocomplete',{attrs:{"items":_vm.platformtemplates,"item-text":"name","item-value":"id","label":"Auswählen","persistent-hint":"","single-line":"","clearable":"","autofocus":""},on:{"click:clear":function($event){_vm.platform = '', _vm.clearFilter()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filter($event)}},model:{value:(_vm.platform),callback:function ($$v) {_vm.platform=$$v},expression:"platform"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.platformfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-close\n              ")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.platform = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-filter-off\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Filter aufheben")])]),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-database-search\n              ")])],1)],1)])]}},{key:"header.ARAINR",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arainr ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.arainrfilter),callback:function ($$v) {_vm.arainrfilter=$$v},expression:"arainrfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filter($event)}},model:{value:(_vm.arainr),callback:function ($$v) {_vm.arainr=$$v},expression:"arainr"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arainrfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-close\n              ")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arainr = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-filter-off\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Filter aufheben")])]),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-database-search\n              ")])],1)],1)])]}},{key:"header.ean",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.ean ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.eanfilter),callback:function ($$v) {_vm.eanfilter=$$v},expression:"eanfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filter($event)}},model:{value:(_vm.ean),callback:function ($$v) {_vm.ean=$$v},expression:"ean"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.eanfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-close\n              ")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.ean = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-filter-off\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Filter aufheben")])]),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-database-search\n              ")])],1)],1)])]}},{key:"header.suppliername",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.lieferant ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.lieferantfilter),callback:function ($$v) {_vm.lieferantfilter=$$v},expression:"lieferantfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-autocomplete',{attrs:{"items":_vm.supplierstocktemplates,"item-text":"supplierName","item-value":"id","label":"Auswählen","persistent-hint":"","single-line":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filter($event)}},model:{value:(_vm.lieferant),callback:function ($$v) {_vm.lieferant=$$v},expression:"lieferant"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.lieferantfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-close\n              ")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.lieferant = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-filter-off\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Filter aufheben")])]),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-database-search\n              ")])],1)],1)])]}},{key:"header.ARWG",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arwg ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.arwgfilter),callback:function ($$v) {_vm.arwgfilter=$$v},expression:"arwgfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"numeric","label":"Geben Sie den Suchbegriff ein","hint":"Erlaubte Vorzeichen =, >, >=, <, <= ","persistent-hint":"","autofocus":true},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filter($event)}},model:{value:(_vm.arwg),callback:function ($$v) {_vm.arwg=$$v},expression:"arwg"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arwgfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-close\n              ")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arwg = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-filter-off\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Filter aufheben")])]),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-database-search\n              ")])],1)],1)])]}},{key:"header.hersteller",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.hersteller ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.herstellerfilter),callback:function ($$v) {_vm.herstellerfilter=$$v},expression:"herstellerfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filter($event)}},model:{value:(_vm.hersteller),callback:function ($$v) {_vm.hersteller=$$v},expression:"hersteller"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.herstellerfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-close\n              ")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.hersteller = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-filter-off\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Filter aufheben")])]),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-database-search\n              ")])],1)],1)])]}},{key:"header.product_type",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.producttype ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.producttypefilter),callback:function ($$v) {_vm.producttypefilter=$$v},expression:"producttypefilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filter($event)}},model:{value:(_vm.producttype),callback:function ($$v) {_vm.producttype=$$v},expression:"producttype"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.producttypefilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-close\n              ")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.producttype = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-filter-off\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Filter aufheben")])]),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-database-search\n              ")])],1)],1)])]}},{key:"header.islocked",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Preistype")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n            Preistypen\n            "),_c('v-chip',{attrs:{"color":"cyan","text-color":"white","small":""}},[_vm._v("\n              L\n            ")]),_vm._v("\n            : Lieferantenpreis,\n            "),_c('v-chip',{attrs:{"color":"cyan","text-color":"white","small":""}},[_vm._v("\n              M\n            ")]),_vm._v("\n            : M.Einkaufspreis,\n            "),_c('v-chip',{attrs:{"color":"red","text-color":"white","small":""}},[_vm._v("\n              S\n            ")]),_vm._v("\n            : Sperrartikelpreis,\n            "),_c('v-chip',{attrs:{"color":"blue","text-color":"white","small":""}},[_vm._v("\n              KB\n            ")]),_vm._v("\n            : Kein Bestpreis,\n            "),_c('v-chip',{attrs:{"color":"orange","text-color":"white","small":""}},[_vm._v("\n              W\n            ")]),_vm._v("\n            : Wiederverkaufspreis\n          ")],1)]),_vm._v(" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.locktype ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.locktypefilter),callback:function ($$v) {_vm.locktypefilter=$$v},expression:"locktypefilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-autocomplete',{attrs:{"items":_vm.locktypes,"item-text":"name","item-value":"value","label":"Preistype auswählen","persistent-hint":"","single-line":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filter($event)},"change":_vm.filter},model:{value:(_vm.locktype),callback:function ($$v) {_vm.locktype=$$v},expression:"locktype"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.locktypefilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-close\n              ")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.locktype = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-filter-off\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Filter aufheben")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-database-search\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Suchen")])])],1)])]}},{key:"item.manualpurchasingprice",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.convertDoubleToCurrency(item.manualpurchasingprice))+"\n      ")]}},{key:"item.ownprice",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"cyan","text-color":"white","small":""}},[_vm._v("\n          "+_vm._s(_vm.convertDoubleToCurrency(item.ownprice))+"\n        ")])]}},{key:"item.minprice",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"orange","text-color":"white","small":""}},[_vm._v("\n          "+_vm._s(_vm.convertDoubleToCurrency(item.minprice))+"\n        ")])]}},{key:"item.platformbestprice",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"blue","text-color":"white","small":""}},[_vm._v("\n          "+_vm._s(_vm.convertDoubleToCurrency(item.platformbestprice))+"\n        ")])]}},{key:"item.ownoffer",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.ispriceexceeded),"text-color":"white","small":""}},[_vm._v("\n          "+_vm._s(_vm.convertDoubleToCurrency(item.ownoffer))+"\n        ")])]}},{key:"item.ispriceexceeded",fn:function(ref){
var item = ref.item;
return [(item.ispriceexceeded == 0)?_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("\n          mdi-check-circle\n        ")]):_vm._e(),_vm._v(" "),(item.ispriceexceeded == 1)?_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("\n          mdi-alert\n        ")]):_vm._e()]}},{key:"item.platformcharges",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.convertDoubleToCurrency(item.platformcharges))+"\n      ")]}},{key:"item.shippingcharge",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.convertDoubleToCurrency(item.shippingcharge))+"\n      ")]}},{key:"item.packagingcost",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.convertDoubleToCurrency(item.packagingcost))+"\n      ")]}},{key:"item.ownpricewithshippingcharge",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.convertDoubleToCurrency(item.ownpricewithshippingcharge))+"\n      ")]}},{key:"item.platformchargewithoutshipping",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.convertDoubleToCurrency(item.platformchargewithoutshipping))+"\n      ")]}},{key:"item.priceprofit",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.convertDoubleToCurrency(item.priceprofit))+"\n      ")]}},{key:"item.pricepercentage",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.convertDoubleToCurrency(item.pricepercentage))+"\n      ")]}},{key:"item.oneunitownoffer",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.convertDoubleToCurrency(item.oneunitownoffer))+"\n      ")]}},{key:"item.pricemarkdown",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.convertDoubleToCurrency(item.pricemarkdown))+"\n      ")]}},{key:"item.ARGW",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.convertDoubleToCurrency(item.ARGW))+"\n      ")]}},{key:"item.islocked",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[(item.islocked == 0 && item.locktype !== null && item.locktype.startsWith('LIEFERANTENPREIS'))?_c('v-chip',{attrs:{"color":"cyan","text-color":"white","small":""}},[_vm._v("\n                L\n              ")]):_vm._e(),_vm._v(" "),(item.islocked == 0 && item.locktype !== null && item.locktype.startsWith('MANUELLEREINKAUFSPREIS'))?_c('v-chip',{attrs:{"color":"green","text-color":"white","small":""}},[_vm._v("\n                M\n              ")]):_vm._e(),_vm._v(" "),(item.islocked == 0 && item.locktype !== null && item.locktype.startsWith('KEINBESTPREIS'))?_c('v-chip',{attrs:{"color":"blue","text-color":"white","small":""}},[_vm._v("\n                KB\n              ")]):_vm._e(),_vm._v(" "),(item.islocked == 1 && item.locktype !== null  && item.locktype.startsWith('SPERRARTIKEL'))?_c('v-chip',{attrs:{"color":"red","text-color":"white","small":""}},[_vm._v("\n                S\n              ")]):_vm._e(),_vm._v(" "),(item.islocked == 0 && item.locktype !== null && item.locktype.startsWith('WIEDERVERKAUFSPREIS'))?_c('v-chip',{attrs:{"color":"orange","text-color":"white","small":""}},[_vm._v("\n                W\n              ")]):_vm._e()],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(item.locktype))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }