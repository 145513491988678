<template>
    <v-app>
      <v-data-table
        :headers="headers"
        :items="supplierstocktemplatemappings"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [50, 100, 150, -1],
          itemsPerPageAllText: 'Alle',
          itemsPerPageText: 'Datensätze pro Seite',
          pageText: '{0}-{1} von {2}'
        }"
      >
          <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Zuordnung der Lieferantenvorlage {{ editdata.templateName }} Platform</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
              v-model="dialog"
              max-width="800px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  class="mx-2"
                  fab
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon dark>
                  mdi-plus
                </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-app-bar
                  dark
                  color="success"
                >
                <v-card-title>
                  <span class="text-h5">Zuordnung hinzufügen</span>
                </v-card-title>
                </v-app-bar>
                <v-card-text>
                  <v-container>
                    <div class="vue-csv-uploader">
                    <v-form>
                      <div class="vue-csv-uploader-part-one">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="editdata.supplier.supplierName"
                          label="Lieferantenname*"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                          <v-select
                              v-model="editdata.supplier.supplierType"
                              :items="$func.getSupplierTypeOptions()"
                              label="Lieferantentype*"
                              item-text="text"
                              item-value="value"
                              disabled
                          ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="editdata.templateName"
                          label="Vorlagenname*"
                          :rules="rules.name"
                          disabled
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                          <v-text-field
                          v-model="editdata.supplierNumber"
                          label="Lieferantennummer*"
                          :rules="rules.number"
                          type="number"
                          disabled
                          required
                          ></v-text-field>
                      </v-col>
                      </v-row>
                      <v-row>
                      <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.filepath"
                            :items="directories"
                            label="Dateipfad*"
                            persistent-hint
                            required
                          >
                              <template v-slot:prepend>
                                <v-tooltip text="Tooltip">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                        icon
                                        color="green"
                                        @click="refreshSupplierDirectories"
                                        >
                                        <v-icon v-bind="props">mdi-cached</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                              </template>
                          </v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                        v-model="editedItem.filename"
                        label="Dateiname*"
                        :rules="rules.name"
                        required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                      <v-row>
                          <v-col cols="6">
                              <v-text-field
                              v-model="editedItem.delimiter"
                              label="Trennzeichen*"
                              required
                              ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                              <v-text-field
                              v-model="editedItem.startRow"
                              label="Startzeile*"
                              type="number"
                              min= 1
                              required
                              >
                              </v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-file-input
                            v-model="selectedtemplatefile"
                            show-size
                            label="Vorlagedatei*"
                            @change="onUpload"
                            @click:clear="clearSelectedTemplate"
                            prepend-icon="mdi-cloud-upload"
                          ></v-file-input>
                        </v-col>  
                      </v-row>
                    </div> <!--End div vue-csv-uploader-part-one-->
                <div class="vue-csv-uploader-part-two"> 
                  <div class="vue-csv-mapping" >
                    <table :class="tableClass">
                                <slot name="thead">
                                    <thead>
                                    <tr>
                                        <th width="20">Erforderliche Felder</th>
                                        <th width="30">Artikel-Felder</th>
                                        <th width="50">CSV-Felder</th>
                                    </tr>
                                    </thead>
                                </slot>
                                <tbody>
                                <tr v-for="(field, key) in fieldsToMap" :key="key">
                                    <td>{{ field.label }}:</td>
                                    <td>
                                        <v-autocomplete v-if="field.key == 'product_id'"
                                        v-model="editedItem.product_id_artikel_column"
                                        :items="pcart04mapFields"
                                        item-value="label"
                                        item-text="label"
                                        ></v-autocomplete>
                                    </td>
                                    <td>
                                        <!---v-autocomplete
                                        v-model="map[field.key]"
                                        :items="firstRow"
                                        item-value="key"
                                        item-text="column"
                                        ></v-autocomplete-->
                                        <select :class="tableSelectClass" :name="`csv_uploader_map_${key}`" v-model="map[field.key]">
                                            <option :value="-1" >Ignorieren</option>
                                            <option v-for="(column, key) in firstRow" :key="key" :value="key">{{ column }}</option>
                                        </select>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                </div> <!-- End div vue-csv-mapping-->
                <v-divider></v-divider>
                <v-col cols="12">
                  <v-radio-group row label="Matchingsart:" v-model="editedItem.matchingsart">
                    <v-radio v-for="(item, key) in matchingoptions" :key="item.value" :label="item.text" :value="item.value" ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6" v-if="editedItem.matchingsart == 3">
                    <v-text-field
                    v-model="editedItem.ARHE"
                    label="Herstellernummer*"
                    :rules="rules.number"
                    type="number"
                    required
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                  label="Beschreibung"
                  v-model="editedItem.description" 
                  hide-details="auto"
                  ></v-text-field>
                </v-col>
              </div> <!-- End div vue-csv-uploader-part-two-->
                    </v-form>
                    </div>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    @click="close"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                  color="primary"
                  @click="save"
                  v-if="csv && editedItem.filepath && editedItem.filename"
                  >
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Sind Sie sicher, dass Sie diesen Lieferant löschen möchten?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        
        <template v-slot:item.supplierName="{ item }">
          {{ editdata.supplier.supplierName }}
        </template>
        <template v-slot:item.templateName="{ item }">
          {{ item.supplierstocktemplate.templateName }}
        </template>
        <template v-slot:item.filename="{ item }">
          {{ item.filename }}
        </template>
        <template v-slot:item.description="{ item }">
          {{ item.description }}
        </template>
        <template v-slot:item.lastAction="{ item }">
          <v-switch
              v-model="item.lastAction"
              color="green"
              disabled
              label=""
              ></v-switch>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            color="blue"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
            color="red"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <template>
        <v-row>
          <v-col cols="3">
            <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
          </v-col>
        </v-row> 
      </template>
      </v-app>
  </template>
  <script>
    import { drop, every, forEach, get, isArray, map, set } from 'lodash';
    import axios from 'axios';
    import Papa from 'papaparse';
    export default {
      props: {
          value: Array,
          editdata: null,
          url: {
              type: String,
              default: '/admin/supplierstocktempplatformmappings'
          },
          mapFields: {
                //required: true
                type: Array,
                default: () => {
                    return [
                    {"key":"product_id", "label":"Artikelnummer", "columnnr":"product_id_columnNr"},
                    {"key":"ean", "label":"EAN", "columnnr":"ean_columnNr"},
                    {"key":"hersteller", "label":"Hersteller", "columnnr":"hersteller_columnNr"},
                    {"key":"suppliernumber1", "label":"Suppliernumber1", "columnnr":"suppliernumber1_columnNr"},
                    {"key":"suppliercountry1", "label":"Suppliercountry1", "columnnr":"suppliercountry1_columnNr"},
                    {"key":"stock1", "label":"Bestand1", "columnnr":"stock1_columnNr"},
                    {"key":"price1", "label":"Preis1", "columnnr":"price1_columnNr"},
                    {"key":"suppliernumber2", "label":"Suppliernumber2", "columnnr":"suppliernumber2_columnNr"},
                    {"key":"suppliercountry2", "label":"Suppliercountry2", "columnnr":"suppliercountry2_columnNr"},
                    {"key":"stock2", "label":"Bestand2", "columnnr":"stock2_columnNr"},
                    {"key":"price2", "label":"Preis2", "columnnr":"price2_columnNr"},
                    {"key":"suppliernumber3", "label":"Suppliernumber3", "columnnr":"suppliernumber3_columnNr"},
                    {"key":"suppliercountry3", "label":"Suppliercountry3", "columnnr":"suppliercountry3_columnNr"},
                    {"key":"stock3", "label":"Bestand3", "columnnr":"stock3_columnNr"},
                    {"key":"price3", "label":"Preis3", "columnnr":"price3_columnNr"},
                    {"key":"suppliernumber4", "label":"Suppliernumber4", "columnnr":"suppliernumber4_columnNr"},
                    {"key":"suppliercountry4", "label":"Suppliercountry4", "columnnr":"suppliercountry4_columnNr"},
                    {"key":"stock4", "label":"Bestand4", "columnnr":"stock4_columnNr"},
                    {"key":"price4", "label":"Preis4", "columnnr":"price4_columnNr"},
                    {"key":"suppliernumber5", "label":"Suppliernumber5", "columnnr":"suppliernumber5_columnNr"},
                    {"key":"suppliercountry5", "label":"Suppliercountry5", "columnnr":"suppliercountry5_columnNr"},
                    {"key":"stock5", "label":"Bestand5", "columnnr":"stock5_columnNr"},
                    {"key":"price5", "label":"Preis5", "columnnr":"price5_columnNr"},
                    {"key":"suppliernumber6", "label":"Suppliernumber6", "columnnr":"suppliernumber6_columnNr"},
                    {"key":"suppliercountry6", "label":"Suppliercountry6", "columnnr":"suppliercountry6_columnNr"},
                    {"key":"stock6", "label":"Bestand6", "columnnr":"stock6_columnNr"},
                    {"key":"price6", "label":"Preis6", "columnnr":"price6_columnNr"},
                    {"key":"suppliernumber7", "label":"Suppliernumber7", "columnnr":"suppliernumber7_columnNr"},
                    {"key":"suppliercountry7", "label":"Suppliercountry7", "columnnr":"suppliercountry7_columnNr"},
                    {"key":"stock7", "label":"Bestand7", "columnnr":"stock7_columnNr"},
                    {"key":"price7", "label":"Preis7", "columnnr":"price7_columnNr"},
                    {"key":"suppliernumber8", "label":"Suppliernumber8", "columnnr":"suppliernumber8_columnNr"},
                    {"key":"suppliercountry8", "label":"Suppliercountry8", "columnnr":"suppliercountry8_columnNr"},
                    {"key":"stock8", "label":"Bestand8", "columnnr":"stock8_columnNr"},
                    {"key":"price8", "label":"Preis8", "columnnr":"price8_columnNr"},
                    {"key":"suppliernumber9", "label":"Suppliernumber9", "columnnr":"suppliernumber9_columnNr"},
                    {"key":"suppliercountry9", "label":"Suppliercountry9", "columnnr":"suppliercountry9_columnNr"},
                    {"key":"stock9", "label":"Bestand9", "columnnr":"stock9_columnNr"},
                    {"key":"price9", "label":"Preis9", "columnnr":"price9_columnNr"},
                    {"key":"suppliernumber10", "label":"Suppliernumber10", "columnnr":"suppliernumber10_columnNr"},
                    {"key":"suppliercountry10", "label":"Suppliercountry10", "columnnr":"suppliercountry10_columnNr"},
                    {"key":"stock10", "label":"Bestand10", "columnnr":"stock10_columnNr"},
                    {"key":"price10", "label":"Preis10", "columnnr":"price10_columnNr"}]
                }
            },
          callback: {
                  type: Function,
                  default: () => ({})
              },
          catch: {
              type: Function,
              default: () => ({})
          },
          finally: {
              type: Function,
              default: () => ({})
          },
          parseConfig: {
                type: Object,
                default() {
                    return {};
                }
            },
            loadBtnText: {
                type: String,
                default: "Laden"
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            autoMatchFields: {
                type: Boolean,
                default: false
            },
            autoMatchIgnoreCase: {
                type: Boolean,
                default: false
            },
            tableClass: {
                type: String,
                default: "table"
            },
            checkboxClass: {
                type: String,
                default: "form-check-input"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },
            fileMimeTypes: {
                type: Array,
                default: () => {
                    return ['text/csv','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'text/x-csv', 'application/vnd.ms-excel', 'text/plain'];
                }
            },
            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
            canIgnore: {
               type: Boolean,
               default: false,
            }
      },
  
      data: () => ({
        form: {
              csv: null,
            },
        filenameRules: [
            v => !!v || 'Dateiname ist erforderlich',
        ],
        fileSelected: false,
        pcart04mapFields:[],
        fieldsToMap: [],
        pcart04fieldsToMap: [],
        map: {},
        hasHeaders: true,
        csv: null,
        sample: null,
        isValidFileMimeType: false,
        selectedtemplatefile:null,
        directories: [],
        supplierstocktemplatemappings: [],
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        rules: {
            name: [val => (val || '').length > 0 || 'Erforderlich'],
            number: [val => val > 0 || `Erforderlich`],
          },
        headers: [
          {
            text: 'Id',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Lieferant', value: 'supplierName' },
          { text: 'Vorlagename', value: 'templateName' },
          { text: 'Vorlagedatei', value: 'filename' },
          { text: 'Beschreibung', value: 'description' },
          { text: 'Status', value: 'lastAction' },
          { text: 'Aktionen', value: 'actions', sortable:false },
        ],
        success:false,
        suppliers: [],
        editedItem: {
          id: 0,
          supplierstockstemplate_id: 0,
          validitytime: 0,
          filepath: null,
          filename: null,
          templateFile: null,
          templateFile_MimeType: null,
          delimiter: ';',
          startRow: 1,
          templateheader: null,
          mapping: null,
          product_id: null,
          product_id_columnNr: -1,
          product_id_artikel_column: null,
          ean: null,
          ean_columnNr: -1,
          hersteller: null,
          hersteller_columnNr: -1,
          suppliernumber1: null,
          suppliernumber1_columnNr: -1,
          suppliercountry1: null,
          suppliercountry1_columnNr: -1,
          stock1: null,
          stock1_columnNr: -1,
          price1: null,
          price1_columnNr: -1,
          issupplier2: 0,
          suppliernumber2: null,
          suppliernumber2_columnNr: -1,
          suppliercountry2: null,
          suppliercountry2_columnNr: -1,
          stock2: null,
          stock2_columnNr: -1,
          price2: null,
          price2_columnNr: -1,
          issupplier3: 0,
          suppliernumber3: null,
          suppliernumber3_columnNr: -1,
          suppliercountry3: null,
          suppliercountry3_columnNr: -1,
          stock3: null,
          stock3_columnNr: -1,
          price3: null,
          price3_columnNr: -1,
          issupplier4: 0,
          suppliernumber4: null,
          suppliernumber4_columnNr: -1,
          suppliercountry4: null,
          suppliercountry4_columnNr: -1,
          stock4: null,
          stock4_columnNr: -1,
          price4: null,
          price4_columnNr: -1,
          issupplier5: 0,
          suppliernumber5: null,
          suppliernumber5_columnNr: -1,
          suppliercountry5: null,
          suppliercountry5_columnNr: -1,
          stock5: null,
          stock5_columnNr: -1,
          price5: null,
          price5_columnNr: -1,
          issupplier6: 0,
          suppliernumber6: null,
          suppliernumber6_columnNr: -1,
          suppliercountry6: null,
          suppliercountry6_columnNr: -1,
          stock6: null,
          stock6_columnNr: -1,
          price6: null,
          price6_columnNr: -1,
          issupplier7: 0,
          suppliernumber7: null,
          suppliernumber7_columnNr: -1,
          suppliercountry7: null,
          suppliercountry7_columnNr: -1,
          stock7: null,
          stock7_columnNr: -1,
          price7: null,
          price7_columnNr: -1,
          issupplier8: 0,
          suppliernumber8: null,
          suppliernumber8_columnNr: -1,
          suppliercountry8: null,
          suppliercountry8_columnNr: -1,
          stock8: null,
          stock8_columnNr: -1,
          price8: null,
          price8_columnNr: -1,
          issupplier9: 0,
          suppliernumber9: null,
          suppliernumber9_columnNr: -1,
          suppliercountry9: null,
          suppliercountry9_columnNr: -1,
          stock9: null,
          stock9_columnNr: -1,
          price9: null,
          price9_columnNr: -1,
          issupplier10: 0,
          suppliernumber10: null,
          suppliernumber10_columnNr: -1,
          suppliercountry10: null,
          suppliercountry10_columnNr: -1,
          stock10: null,
          stock10_columnNr: -1,
          price10: null,
          price10_columnNr: -1,
          matchingsart: 0,
          ARHE: null,
          description: null,
          lastAction: 1,
        },
        defaultItem: {
            id: 0,
            supplierstockstemplate_id: 0,
            validitytime: 0,
            filepath: null,
            filename: null,
            templateFile: null,
            templateFile_MimeType: null,
            delimiter: ';',
            startRow: 1,
            templateheader: null,
            mapping: null,
            product_id: null,
            product_id_columnNr: -1,
            product_id_artikel_column: null,
            ean: null,
            ean_columnNr: -1,
            hersteller: null,
            hersteller_columnNr: -1,
            suppliernumber1: null,
            suppliernumber1_columnNr: -1,
            suppliercountry1: null,
            suppliercountry1_columnNr: -1,
            stock1: null,
            stock1_columnNr: -1,
            price1: null,
            price1_columnNr: -1,
            issupplier2: 0,
            suppliernumber2: null,
            suppliernumber2_columnNr: -1,
            suppliercountry2: null,
            suppliercountry2_columnNr: -1,
            stock2: null,
            stock2_columnNr: -1,
            price2: null,
            price2_columnNr: -1,
            issupplier3: 0,
            suppliernumber3: null,
            suppliernumber3_columnNr: -1,
            suppliercountry3: null,
            suppliercountry3_columnNr: -1,
            stock3: null,
            stock3_columnNr: -1,
            price3: null,
            price3_columnNr: -1,
            issupplier4: 0,
            suppliernumber4: null,
            suppliernumber4_columnNr: -1,
            suppliercountry4: null,
            suppliercountry4_columnNr: -1,
            stock4: null,
            stock4_columnNr: -1,
            price4: null,
            price4_columnNr: -1,
            issupplier5: 0,
            suppliernumber5: null,
            suppliernumber5_columnNr: -1,
            suppliercountry5: null,
            suppliercountry5_columnNr: -1,
            stock5: null,
            stock5_columnNr: -1,
            price5: null,
            price5_columnNr: -1,
            issupplier6: 0,
            suppliernumber6: null,
            suppliernumber6_columnNr: -1,
            suppliercountry6: null,
            suppliercountry6_columnNr: -1,
            stock6: null,
            stock6_columnNr: -1,
            price6: null,
            price6_columnNr: -1,
            issupplier7: 0,
            suppliernumber7: null,
            suppliernumber7_columnNr: -1,
            suppliercountry7: null,
            suppliercountry7_columnNr: -1,
            stock7: null,
            stock7_columnNr: -1,
            price7: null,
            price7_columnNr: -1,
            issupplier8: 0,
            suppliernumber8: null,
            suppliernumber8_columnNr: -1,
            suppliercountry8: null,
            suppliercountry8_columnNr: -1,
            stock8: null,
            stock8_columnNr: -1,
            price8: null,
            price8_columnNr: -1,
            issupplier9: 0,
            suppliernumber9: null,
            suppliernumber9_columnNr: -1,
            suppliercountry9: null,
            suppliercountry9_columnNr: -1,
            stock9: null,
            stock9_columnNr: -1,
            price9: null,
            price9_columnNr: -1,
            issupplier10: 0,
            suppliernumber10: null,
            suppliernumber10_columnNr: -1,
            suppliercountry10: null,
            suppliercountry10_columnNr: -1,
            stock10: null,
            stock10_columnNr: -1,
            price10: null,
            price10_columnNr: -1,
            matchingsart: 0,
            ARHE: null,
            description: null,
            lastAction: 1,
        },
        matchingoptions: 
          [
              //{ text: 'mit Cartomak', value: '1' },
              { text: 'mit EAN', value: 2 },
              { text: 'mit Hersteller-Artikelnummern', value: 3 }
          ],
      }),
  
      computed: {
        formTitle () {
          return this.editedItem.id === 0 ? 'Zuordnung hinzufügen' : 'Zuordnung bearbeiten'
        },
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
  
      created () {
        this.editedItem.supplierstockstemplate_id = this.editdata.id;
        this.getSupplierStocktTemplateMappings();
        this.getArtikelColumns();
        this.getDirectories('/admin/supplierstocktemplatedirectories/0');
        this.fieldsToMap =  this.mapFields;
        if (isArray(this.pcart04mapFields)) {
            this.pcart04fieldsToMap = map(this.pcart04mapFields, (item) => {
                return {
                    key: item,
                    label: item
                };
            });
        } else {
            this.pcart04mapFields = map(this.pcart04mapFields, (label, key) => {
                return {
                    key: key,
                    label: label
                };
            });
        }
        this.form.csv = this.buildMappedCsv();
      },
  
      methods: {
        back: function(){
            window.location = location.protocol + "//" + location.hostname + "/admin/supplier/"+this.editdata.supplier_id+"/supplierstocktemplates";
        },
        buildMappedCsv() {
            const _this = this;
            this.editedItem.templateFile = _this.selectedtemplatefile == null ? null : _this.selectedtemplatefile.name;
            let selectedfields = _.split(this.csv, ',' );
            this.editedItem.templateheader = selectedfields;
            this.editedItem.mapping = _this.map;
            this.editedItem.product_id_columnNr = get(_this.map, 'product_id', -1);
            this.editedItem.product_id = this.editedItem.product_id_columnNr < 0 ? null : get(selectedfields, this.editedItem.product_id_columnNr);
            this.editedItem.ean_columnNr = get(_this.map, 'ean', -1);
            this.editedItem.ean = this.editedItem.ean_columnNr < 0 ? null : get(selectedfields, this.editedItem.ean_columnNr);
            this.editedItem.hersteller_columnNr = get(_this.map, 'hersteller', -1);
            this.editedItem.hersteller = this.editedItem.hersteller_columnNr < 0 ? null : get(selectedfields, this.editedItem.hersteller_columnNr);
            //supplier1
            this.editedItem.suppliernumber1_columnNr = get(_this.map, 'suppliernumber1', -1);
            this.editedItem.suppliernumber1 = this.editedItem.suppliernumber1_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliernumber1_columnNr);
            this.editedItem.suppliercountry1_columnNr = get(_this.map, 'suppliercountry1', -1);
            this.editedItem.suppliercountry1 = this.editedItem.suppliercountry1_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliercountry1_columnNr);
            this.editedItem.stock1_columnNr = get(_this.map, 'stock1', -1);
            this.editedItem.stock1 = this.editedItem.stock1_columnNr < 0 ? null : get(selectedfields, this.editedItem.stock1_columnNr);
            this.editedItem.price1_columnNr = get(_this.map, 'price1', -1);
            this.editedItem.price1 = this.editedItem.price1_columnNr < 0 ? null : get(selectedfields, this.editedItem.price1_columnNr);
            //supplier2
            this.editedItem.suppliernumber2_columnNr = get(_this.map, 'suppliernumber2', -1);
            this.editedItem.suppliernumber2 = this.editedItem.suppliernumber2_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliernumber2_columnNr);
            this.editedItem.suppliercountry2_columnNr = get(_this.map, 'suppliercountry2', -1);
            this.editedItem.suppliercountry2 = this.editedItem.suppliercountry2_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliercountry2_columnNr);
            this.editedItem.stock2_columnNr = get(_this.map, 'stock2', -1);
            this.editedItem.stock2 = this.editedItem.stock2_columnNr < 0 ? null : get(selectedfields, this.editedItem.stock2_columnNr);
            this.editedItem.price2_columnNr = get(_this.map, 'price2', -1);
            this.editedItem.price2 = this.editedItem.price2_columnNr < 0 ? null : get(selectedfields, this.editedItem.price2_columnNr);
            //supplier3
            this.editedItem.suppliernumber3_columnNr = get(_this.map, 'suppliernumber3', -1);
            this.editedItem.suppliernumber3 = this.editedItem.suppliernumber3_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliernumber3_columnNr);
            this.editedItem.suppliercountry3_columnNr = get(_this.map, 'suppliercountry3', -1);
            this.editedItem.suppliercountry3 = this.editedItem.suppliercountry3_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliercountry3_columnNr);
            this.editedItem.stock3_columnNr = get(_this.map, 'stock3', -1);
            this.editedItem.stock3 = this.editedItem.stock3_columnNr < 0 ? null : get(selectedfields, this.editedItem.stock3_columnNr);
            this.editedItem.price3_columnNr = get(_this.map, 'price3', -1);
            this.editedItem.price3 = this.editedItem.price3_columnNr < 0 ? null : get(selectedfields, this.editedItem.price3_columnNr);
            //supplier4
            this.editedItem.suppliernumber4_columnNr = get(_this.map, 'suppliernumber4', -1);
            this.editedItem.suppliernumber4 = this.editedItem.suppliernumber4_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliernumber4_columnNr);
            this.editedItem.suppliercountry4_columnNr = get(_this.map, 'suppliercountry4', -1);
            this.editedItem.suppliercountry4 = this.editedItem.suppliercountry4_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliercountry4_columnNr);
            this.editedItem.stock4_columnNr = get(_this.map, 'stock4', -1);
            this.editedItem.stock4 = this.editedItem.stock4_columnNr < 0 ? null : get(selectedfields, this.editedItem.stock4_columnNr);
            this.editedItem.price4_columnNr = get(_this.map, 'price4', -1);
            this.editedItem.price4 = this.editedItem.price4_columnNr < 0 ? null : get(selectedfields, this.editedItem.price4_columnNr);
            //supplier5
            this.editedItem.suppliernumber5_columnNr = get(_this.map, 'suppliernumber5', -1);
            this.editedItem.suppliernumber5 = this.editedItem.suppliernumber5_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliernumber5_columnNr);
            this.editedItem.suppliercountry5_columnNr = get(_this.map, 'suppliercountry5', -1);
            this.editedItem.suppliercountry5 = this.editedItem.suppliercountry5_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliercountry5_columnNr);
            this.editedItem.stock5_columnNr = get(_this.map, 'stock5', -1);
            this.editedItem.stock5 = this.editedItem.stock5_columnNr < 0 ? null : get(selectedfields, this.editedItem.stock5_columnNr);
            this.editedItem.price5_columnNr = get(_this.map, 'price5', -1);
            this.editedItem.price5 = this.editedItem.price5_columnNr < 0 ? null : get(selectedfields, this.editedItem.price5_columnNr);
            //supplier6
            this.editedItem.suppliernumber6_columnNr = get(_this.map, 'suppliernumber6', -1);
            this.editedItem.suppliernumber6 = this.editedItem.suppliernumber6_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliernumber6_columnNr);
            this.editedItem.suppliercountry6_columnNr = get(_this.map, 'suppliercountry6', -1);
            this.editedItem.suppliercountry6 = this.editedItem.suppliercountry6_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliercountry6_columnNr);
            this.editedItem.stock6_columnNr = get(_this.map, 'stock6', -1);
            this.editedItem.stock6 = this.editedItem.stock6_columnNr < 0 ? null : get(selectedfields, this.editedItem.stock6_columnNr);
            this.editedItem.price6_columnNr = get(_this.map, 'price6', -1);
            this.editedItem.price6 = this.editedItem.price6_columnNr < 0 ? null : get(selectedfields, this.editedItem.price6_columnNr);
            //supplier7
            this.editedItem.suppliernumber7_columnNr = get(_this.map, 'suppliernumber7', -1);
            this.editedItem.suppliernumber7 = this.editedItem.suppliernumber7_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliernumber7_columnNr);
            this.editedItem.suppliercountry7_columnNr = get(_this.map, 'suppliercountry7', -1);
            this.editedItem.suppliercountry7 = this.editedItem.suppliercountry7_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliercountry7_columnNr);
            this.editedItem.stock7_columnNr = get(_this.map, 'stock7', -1);
            this.editedItem.stock7 = this.editedItem.stock7_columnNr < 0 ? null : get(selectedfields, this.editedItem.stock7_columnNr);
            this.editedItem.price7_columnNr = get(_this.map, 'price7', -1);
            this.editedItem.price7 = this.editedItem.price7_columnNr < 0 ? null : get(selectedfields, this.editedItem.price7_columnNr);
            //supplier8
            this.editedItem.suppliernumber8_columnNr = get(_this.map, 'suppliernumber8', -1);
            this.editedItem.suppliernumber8 = this.editedItem.suppliernumber8_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliernumber8_columnNr);
            this.editedItem.suppliercountry8_columnNr = get(_this.map, 'suppliercountry8', -1);
            this.editedItem.suppliercountry8 = this.editedItem.suppliercountry8_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliercountry8_columnNr);
            this.editedItem.stock8_columnNr = get(_this.map, 'stock8', -1);
            this.editedItem.stock8 = this.editedItem.stock8_columnNr < 0 ? null : get(selectedfields, this.editedItem.stock8_columnNr);
            this.editedItem.price8_columnNr = get(_this.map, 'price8', -1);
            this.editedItem.price8 = this.editedItem.price8_columnNr < 0 ? null : get(selectedfields, this.editedItem.price8_columnNr);
            //supplier9
            this.editedItem.suppliernumber9_columnNr = get(_this.map, 'suppliernumber9', -1);
            this.editedItem.suppliernumber9 = this.editedItem.suppliernumber9_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliernumber9_columnNr);
            this.editedItem.suppliercountry9_columnNr = get(_this.map, 'suppliercountry9', -1);
            this.editedItem.suppliercountry9 = this.editedItem.suppliercountry9_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliercountry9_columnNr);
            this.editedItem.stock9_columnNr = get(_this.map, 'stock9', -1);
            this.editedItem.stock9 = this.editedItem.stock9_columnNr < 0 ? null : get(selectedfields, this.editedItem.stock9_columnNr);
            this.editedItem.price9_columnNr = get(_this.map, 'price9', -1);
            this.editedItem.price9 = this.editedItem.price9_columnNr < 0 ? null : get(selectedfields, this.editedItem.price9_columnNr);
            //supplier10
            this.editedItem.suppliernumber10_columnNr = get(_this.map, 'suppliernumber10', -1);
            this.editedItem.suppliernumber10 = this.editedItem.suppliernumber10_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliernumber10_columnNr);
            this.editedItem.suppliercountry10_columnNr = get(_this.map, 'suppliercountry10', -1);
            this.editedItem.suppliercountry10 = this.editedItem.suppliercountry10_columnNr < 0 ? null : get(selectedfields, this.editedItem.suppliercountry10_columnNr);
            this.editedItem.stock10_columnNr = get(_this.map, 'stock10', -1);
            this.editedItem.stock10 = this.editedItem.stock10_columnNr < 0 ? null : get(selectedfields, this.editedItem.stock10_columnNr);
            this.editedItem.price10_columnNr = get(_this.map, 'price10', -1);
            this.editedItem.price10 = this.editedItem.price10_columnNr < 0 ? null : get(selectedfields, this.editedItem.price10_columnNr);

            let csv = this.csv;
            console.log('CSV: '+csv);

            let newRow = {};
            let i = 0;
            return map(_this.map, (column, field) => {
                console.log('i: '+i);
                console.log('Column: '+column);
                console.log('Field: '+field);
                console.log("Get: "+JSON.stringify(get(csv, column)));
                if(column < 0){
                    set(newRow, field, 'Ignorieren');
                }
                else {
                    // get(csv, column);
                    set(newRow, field, get(_this.editedItem.templateheader, column));
                }
                set(newRow, get(this.fieldsToMap, i).columnnr, column);
                i++;
                return newRow;
            });
        },
        getArtikelColumns: function(){
            axios.get('/admin/getallartikelcolumns')
            .then(function (response) {
                this.pcart04mapFields = response.data;
            }.bind(this));
        },
        getDirectories: function(url){
          axios.get(url)
          .then(function (response) {
              this.directories = response.data;
          }.bind(this));
        },
        refreshSupplierDirectories() {
          this.getDirectories('/admin/supplierstocktemplatedirectories/1');
        },
        async clearSelectedTemplate(){
            const _this = this;
            _this.editedItem.templateFile = null;
            _this.sample = null;
            _this.csv = null;
            _this.editedItem.templateheader=null;
        },
        onUpload() {
          const _this = this;
          try {
              if (_this.selectedtemplatefile) {
                  _this.clearSelectedTemplate();
                
                  console.log('Selectedfile: '+_this.selectedtemplatefile.name);
                  let reader = new FileReader();
                  reader.readAsText(this.selectedtemplatefile, "UTF-8");
                  reader.onload = function (evt) {
                      _this.sample = get(Papa.parse(evt.target.result, { preview: 1, skipEmptyLines: true }), "data");
                      //_this.sample[0].unshift('Ignorieren');
                      console.log('Sample: '+_this.sample);
                      _this.csv = _this.sample; //get(Papa.parse(_this.sample, { skipEmptyLines: true }), "data");
                      console.log('onUpload csv: '+_this.csv);
                  };
                  reader.onerror = function () {
                  };
              }
          } catch (error) { 
          }
      },
      async getSupplierStocktTemplateMappings() {
        axios.get('/admin/supplierstocktemplate/'+this.editdata.id+'/getsupplierstocktemplateplatformmappings')
            .then(function (response) { 
                this.supplierstocktemplatemappings = response.data;
            }.bind(this));
      },
      showSupplierStockTemplateMappings(item){
        let supplierstocktemplatemappings_url = 'admin/supplierstocktemplate/'+item.id+'/supplierstocktemplatemappings';
        window.location = location.protocol + "//" + location.hostname + supplierstocktemplatemappings_url;
      },     
      editItem (item) {
        //v-if="editedItem.templateheader"
        console.log('item-templateheader: '+item.templateheader);
        this.editedItem = item;
        this.map = item.mapping;
        /*this.map = {"supplierArticleNumber_ColumnNr": this.editedItem.supplierArticleNumber_ColumnNr,
                    "price_ColumnNr":this.editedItem.price_ColumnNr,"quantity_ColumnNr":this.editedItem.quantity_ColumnNr,
                    "tyresInParcel_ColumnNr":this.editedItem.tyresInParcel_ColumnNr,"EAN_ColumnNr":this.editedItem.EAN_ColumnNr};
                    */
        this.csv = item.templateheader;
        this.dialog = true
      },
      deleteItem (item) {
        this.editedItem = item;
        this.dialog = true
        /* axios.get('/admin/suppliers/'+item.id+'/edit')
            .then(function (response) { 
                this.editedItem = response.data;
                this.dialogDelete = true
            }.bind(this)); */
      },
      deleteItemConfirm () {
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = this.defaultItem; //Object.assign({}, this.defaultItem)
        })
      }, 
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = this.defaultItem;//Object.assign({}, this.defaultItem)
        })
      },
      save () {
        const _this = this;
        this.form.csv = this.buildMappedCsv();
        let csvdata = this.form.csv.shift();
        this.$emit('input', this.form.csv);
       // this.editedItem.templateheader = csvdata;
       //this.editedItem.templateheader = this.editedItem.templateheader;
        const artikel_price = this.pcart04mapFields.find(element => element.key == this.editedItem.artikel_preis_ColumnNr);
        const artikel_tyresInParcel = this.pcart04mapFields.find(element => element.key == this.editedItem.artikel_tyresInParcel_ColumnNr);
        this.editedItem.artikel_preis= artikel_price == null ? null : artikel_price.label;
        this.editedItem.artikel_tyresInParcel= artikel_tyresInParcel == null ? null : artikel_tyresInParcel.label;
        if(this.editedItem.id > 0){
            axios.put(this.url+'/'+this.editedItem.id,
                this.editedItem,
            ).then(response => {
                _this.callback(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            axios.post(this.url,
                this.editedItem,
            ).then(response => {
                _this.callback(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }
          // Hide the modal manually
          this.$nextTick(() => {
             // this.resetModal();
              this.$bvModal.hide('modal-prevent-closing')
          })
          this.close()
        },
        showMsgBoxOne(callback, change) {
          this.$bvModal.msgBoxOk(callback.data)
          .then(value => {
              if(change)
              {
                this.getSupplierStocktTemplateMappings();
              }
          })
          .catch(err => {
              // An error occurred
              console.log(err);
          })
        },
      },
      computed: {
            firstRow() {
                this.editedItem.templateheader = this.editedItem.templateheader == null ? get(this, "sample.0") : this.editedItem.templateheader;
                return this.editedItem.templateheader;
            },
            pcart04Row() {
                return get(this, "sample.0");
            },
            showErrorMessage() {
                return this.fileSelected && !this.isValidFileMimeType;
            },

            disabledNextButton() {
                return this.editedItem.templateFile == null;
            },
        },
    }
  </script>